import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { GeneralData } from '@core/models/profile/general-data.model';
import { SettingsData } from '@core/models/profile/settings-data.model';
import { Subject, Subscription } from '@node_modules/rxjs';
import { ProfileService } from '@shared/services/profile.service';
import { ActivatedRoute } from '@angular/router';
import { Profile } from '@core/models/profile/profile.model';
import { Flags } from '@core/enums/generic/flags.enum';
import { Users } from '@core/models/profile/users.model';
import { LocaleService } from '@emendis/complete';
import * as faker from 'faker';
import { Project } from '@core/models/project.model';
import { ProjectService } from '@shared/services/project.service';
import { GroupService } from '@shared/services/group.service';
import { takeUntil } from '@node_modules/rxjs/internal/operators';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']

})
export class ProfileComponent implements OnInit, OnDestroy {
  @Input()
  public profile: Profile;
  public generalCard: GeneralData;
  public settingsCard: SettingsData;
  public userCardData: Users[];
  public selectLanguage: any;
  public flag = Flags;
  public profileServiceData: any;
  public currentFlag: Flags = this.flag.netherlands;
  // TODO replace string with back-end data.
  public currentLanguage: string = 'Netherlands';
  private destroy$: Subject<boolean> = new Subject<boolean>();
  private profileDetails: Subscription = new Subscription();
  private routePath: Subscription = new Subscription();
  private project: Project;
  private projectId: string;

  constructor(private profileService: ProfileService,
              private activatedRoute: ActivatedRoute,
              private projectService: ProjectService,
              private groupService: GroupService,
              private localeService: LocaleService) {
  }

  public ngOnInit(): void {
    this.profileService.getFakeData().pipe(takeUntil(this.destroy$)).subscribe(response => this.profileServiceData = response.data);
    this.routePath = this.activatedRoute.parent.params.subscribe(event => {
      this.projectId = event.id;
      this.projectService.getProjectById(event.id).pipe(takeUntil(this.destroy$)).subscribe((project: Project) => {
        this.project = project;
        this.makeCardDetailRequests();
        this.setProjectDetailsCards();
        this.getLanguages();
      });
    });
  }

  public setGeneralData(project: Project): void {
    const projectNumber = project.number;
    const groupID = this.project.group_id;

    this.groupService.getGroupDataByGroupID(groupID).subscribe((res) => {

      const groupResponse = Object(res);
      const mainAddress = groupResponse.addresses[0];
      const billingAddress = groupResponse.addresses[1];
      this.generalCard = {
        image_url: faker.image.imageUrl(150, 150),
        company: groupResponse.name,
        project_number: projectNumber,
        full_address: `${mainAddress.street} ${mainAddress.housenumber}  <br /> ${mainAddress.zipcode} ${mainAddress.city} <br />${mainAddress.country}`,
        full_billing_address: `${billingAddress.addressline_1} <br />${billingAddress.street} ${billingAddress.housenumber} <br />${billingAddress.zipcode} ${billingAddress.city} <br /> ${billingAddress.country}`,
        phone_number: mainAddress.phone,
        email: mainAddress.email,
        zip_code: mainAddress.zipcode,
        house_number: mainAddress.housenumber,
        street: mainAddress.street,
        city: mainAddress.city,
        country: mainAddress.country,
        is_billing: groupResponse.is_billing,
        billing_company_name: billingAddress.addressline_1,
        billing_zip_code: billingAddress.zipcode,
        billing_house_number: billingAddress.housenumber,
        billing_street: billingAddress.street,
        billing_city: billingAddress.city,
        billing_country: billingAddress.country
      };
    });
  }

  public changeGeneralData(event: any): void {
    event.id = this.project.group_id;
    this.groupService.changeGeneralData(event).subscribe(res => this.setProjectDetailsCards()
    );
  }

  public getLanguages(): void {
    this.localeService.list().subscribe(event => {
      this.displayLanguages(event);
    });
  }

  public displayLanguages(event: any): any {
    this.selectLanguage = [];
    // TODO get data from back-end.
    const flagArray = ['./assets/flags/nl.svg', './assets/flags/en.svg'];

    for (let i = 0; i < event.data.length; i++) {
      this.selectLanguage.push({
        value: event.data[i].id,
        label: event.data[i].name,
        url: flagArray[i]
      });
    }
  }

  public makeCardDetailRequests(): void {
    /* TODO Get correct data from profileService.
    this.profileDetails = this.profileService.getProjectCardDetails(this.projectId).subscribe((projectResponse) => {
      this.profile = { ...this.profile, ...projectResponse };
      // this.setProjectDetailsCards();
    });
    */
    this.setProjectDetailsCards();
  }

  public setProjectDetailsCards(): void {
    this.setGeneralData(this.project);

    this.settingsCard = {
      fullName: faker.name.findName(),
      emailAddress: faker.internet.email(),
      languageSettings: faker.address.country(),
      password: faker.internet.password(),
      flag: this.currentFlag,
      emailNotifications: faker.random.boolean()
    };
    this.userCardData = this.profileServiceData.display_users;
  }

  public changeData(event: any): void {
    if (event) {
      const language = this.changeLanguage(event);
      this.changeFlag(language);

    }
  }

  public changeLanguage(event: any): string {
    this.selectLanguage.forEach(element => {
      if (element.value === event.value.languageSettings) {
        return this.currentLanguage = element.label;
      }
    });
    return this.currentLanguage;
  }

  public changeFlag(event: any): any {
    const flagArray = Object.keys(this.flag);
    const eventLower = event.toLowerCase();

    for (const key of flagArray) {
      if (key === eventLower) {
        this.currentFlag = this.flag[key];
        this.makeCardDetailRequests();
        return this.currentFlag;
      }
    }
  }

  public ngOnDestroy(): void {
    this.routePath.unsubscribe();
    this.profileDetails.unsubscribe();
    this.destroy$.next();
  }
}
