import { Component, OnInit } from '@angular/core';
import { DynamicDialogRef } from '@node_modules/primeng/components/dynamicdialog/dynamicdialog-ref';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Settings } from '@node_modules/@types/tinymce';
import { DynamicDialogConfig } from '@node_modules/primeng/components/dynamicdialog/dynamicdialog-config';
import { TicketService } from '@shared/services/helpdesk/ticket.service';
import { Helpdeskticket } from '@shared/models/helpdeskticket.model';
import { PreviewItem } from '@shared/models/preview-item.model';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-helpdesk-ticket-overview',
  templateUrl: './helpdesk-ticket-overview.component.html',
  styleUrls: ['./helpdesk-ticket-overview.component.scss']
})
export class HelpdeskTicketOverviewComponent implements OnInit {
  public form: FormGroup = this.formBuilder.group({
    content: ['', Validators.required],
    files: [null]
  });
  public ticket: Helpdeskticket;
  public uploadedFiles: any[];
  public previewItems: PreviewItem[];
  public config: Settings;

  constructor(
    public activeModal: DynamicDialogRef,
    public activeModalConfig: DynamicDialogConfig,
    private formBuilder: FormBuilder,
    private ticketService: TicketService,
    private domSanitizer: DomSanitizer
  ) {
    this.editTinymce();
    this.uploadedFiles = [];
  }

  public ngOnInit(): void {
    this.ticket = this.activeModalConfig.data;
  }

  // TODO: return in the right format to the BE
  public onSubmit(): void {
    if (this.form.valid) {
      this.ticket.timeline_items.push(this.form.value.content);
      this.ticket.attachments.push(this.form.value.files);
    }
  }

  public onCancel(): void {
    this.activeModal.close();
  }

  public showPreviewer(): void {
    this.previewItems = this.form.value.files.map(item => {
      return {
        name: item.name,
        src: this.domSanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(item))
      };
    });
  }

  public closePreviewer(): void {
    this.previewItems = null;
  }

  private editTinymce(): void {
    this.config = {
      menubar: false,
      plugins: 'lists link',
      toolbar: 'bold italic strikethrough underline | bullist numlist link'
    };
  }
}
