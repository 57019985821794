import { Component, OnInit } from '@angular/core';
import { TableColumn, TableColumnTypes } from '@emendis/ui';
import { AllProjects } from '@features/project-manager/components/projects-table/all-projects.model';
import { AllProjectsService } from '@shared/services/all-projects.service';
import { first } from '@node_modules/rxjs/internal/operators';

@Component({
  selector: 'app-select-project',
  templateUrl: './select-manager-project.component.html',
  styleUrls: ['./select-manager-project.component.scss']
})

export class SelectManagerProjectComponent implements OnInit {
  public innerWidth: number;
  public tableColumns: TableColumn[];
  public paginator: {} = {};
  public allProjectsServicesData: AllProjects[];
  public allRows: AllProjects[];

  constructor(private allProjectsService: AllProjectsService) {
  }

  public ngOnInit(): void {
    this.allProjectsService.index().pipe(first())
      .subscribe(response => this.allProjectsServicesData = response.data);
    this.allRows = this.allProjectsServicesData;
    this.paginator = {
      first: 1,
      rows: 10,
      total: this.allRows.length,
      current_page: 1,
      total_pages: Math.ceil(this.allRows.length / 10),
      per_page: 10
    };
    this.innerWidth = window.innerWidth;
    this.tableColumns = TableColumn.getColumns([
      {
        field: 'project_id',
        header: 'Project ID',
        type: TableColumnTypes.NUMBER,
        filterable: false,
      },
      {
        field: 'customer',
        header: 'Client',
        type: TableColumnTypes.STRING,
        filterable: false
      },
      {
        field: 'project',
        header: 'Project',
        type: TableColumnTypes.STRING,
        filterable: false
      },
      {
        field: 'project_type',
        header: 'Type',
        type: TableColumnTypes.STRING,
        filterable: false
      },
      {
        field: 'project_status',
        header: 'Status',
        type: TableColumnTypes.STRING,
        filterable: false
      }
    ]);
  }
}
