import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { DynamicDialogRef } from '@node_modules/primeng/components/dynamicdialog/dynamicdialog-ref';
import { Settings } from '@node_modules/@types/tinymce';
import { DomSanitizer } from '@angular/platform-browser';
import { PreviewItem } from '@shared/models/preview-item.model';
import { HelpdeskFormService } from '@shared/services/helpdesk/helpdesk-form.service';
import { takeUntil } from '@node_modules/rxjs/internal/operators';
import { Subject } from '@node_modules/rxjs';
import { BrowserType } from '@core/enums/helpdesk/browser-type.enum';

@Component({
  selector: 'app-helpdesk-ticket-form',
  templateUrl: './helpdesk-ticket-form.component.html',
  styleUrls: ['./helpdesk-ticket-form.component.scss']
})

export class HelpdeskTicketFormComponent implements OnInit {
  public form = this.formBuilder.group({
    subject: [null, Validators.required],
    type: [null, Validators.required],
    browser: [BrowserType.chrome, Validators.required],
    description: [null, Validators.required],
    attachments: [null]
  });
  public previewItems: PreviewItem[];
  public config: Settings;
  public helpdeskFormData: any;
  public isBrowserSelectionDisabled: boolean = false;
  public currentBrowser: BrowserType;
  public formData: any;
  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    public activeModal: DynamicDialogRef,
    private formBuilder: FormBuilder,
    private domSanitizer: DomSanitizer,
    private helpdeskFormService: HelpdeskFormService
  ) {
    this.editTinymce();
  }

  public ngOnInit(): void {
    this.helpdeskFormService.currentMessage.subscribe(data => this.formData = data);
    this.currentBrowser = this.helpdeskFormService.detectBrowser();
    this.helpdeskFormService.index().pipe(takeUntil(this.destroy$))
      .subscribe(response => this.helpdeskFormData = response.data);
  }

  public onSubmit(): void {
    if (this.isBrowserSelectionDisabled) {
      this.assignBrowser();
    }

    this.formData = this.form.value;
    this.helpdeskFormService.getFormData(this.formData);
    this.activeModal.close();
  }

  public onCancel(): void {
    this.activeModal.close();
  }

  public showPreviewer(): void {
    this.previewItems = this.form.value.files.map(item => {
      return {
        name: item.name,
        src: this.domSanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(item))
      };
    });
  }

  public closePreviewer(): void {
    this.previewItems = null;
  }

  public assignBrowser(): void {
    this.form.value.browser = this.currentBrowser;
  }

  public ToggleBrowserSelection(): void {
    this.isBrowserSelectionDisabled = !this.isBrowserSelectionDisabled;
  }

  private editTinymce(): void {
    this.config = {
      menubar: false,
      plugins: 'lists link image paste',
      toolbar: 'bold italic strikethrough underline | bullist numlist link',
      paste_data_images: true
    };
  }
}
