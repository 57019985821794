import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { DialogService } from '@node_modules/primeng/components/dynamicdialog/dialogservice';
import { TicketService } from '@shared/services/helpdesk/ticket.service';
import { TranslateService } from '@node_modules/@ngx-translate/core';
import { HelpdeskTicketOverviewComponent } from '@shared/components/helpdesk-ticket-overview/helpdesk-ticket-overview.component';
import { HelpdeskTicketFormComponent } from '@shared/components/helpdesk-ticket-form/helpdesk-ticket-form.component';
import { Helpdeskticket } from '@shared/models/helpdeskticket.model';
import { takeUntil } from '@node_modules/rxjs/internal/operators';
import { Subject } from '@node_modules/rxjs';

@Component({
  selector: 'app-helpdesk-tickets-widget',
  templateUrl: './helpdesk-tickets-widget.component.html',
  styleUrls: ['./helpdesk-tickets-widget.component.scss']
})
export class HelpdeskTicketsWidgetComponent implements OnInit, OnDestroy {
  @Output()
  public addTicket: EventEmitter<void> = new EventEmitter();

  public tickets: Helpdeskticket[];
  public ticket: Helpdeskticket;
  private destroy$: Subject<boolean> = new Subject<boolean>();
  private dialogStyle: object;

  constructor(
    private translateService: TranslateService,
    private ticketService: TicketService,
    private dialogService: DialogService
  ) {
    this.tickets = [];
    this.dialogStyle = {
      overflowY: 'scroll',
      background: 'white',
      padding: '20px',
      maxHeight: '80vh'
    };
  }

  public ngOnInit(): void {
    this.ticketService.index().pipe(takeUntil(this.destroy$))
      .subscribe(response => this.tickets = response.data);
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
  }

  public onOpenTicketDialog(ticket: Helpdeskticket): void {
    event.preventDefault();

    this.dialogService.open(HelpdeskTicketOverviewComponent, {
      header: this.translateService.instant('headers.helpdeskticket'),
      data: ticket,
      styleClass: 'helpdesk-ticket-overview',
      style: this.dialogStyle,
      dismissableMask: true
    });
  }

  public onOpenNewTicketDialog(): void {
    event.preventDefault();

    this.dialogService.open(HelpdeskTicketFormComponent, {
      header: this.translateService.instant('headers.new-helpdeskticket'),
      styleClass: 'helpdesk-ticket-form',
      style: this.dialogStyle,
      dismissableMask: true
    });
  }
}
