import { Component, EventEmitter, OnDestroy } from '@angular/core';
import { ProgressCircle } from '@features/models/progress-circle.model';
import { HoursOverviewService } from '@shared/services/hours-overview-service.service';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-hours-overview',
  templateUrl: './hours-overview.component.html',
  styleUrls: ['./hours-overview.component.scss']
})
export class HoursOverviewComponent implements OnDestroy {
  public isMobile: boolean;
  public circles: ProgressCircle[] = [];
  public buttonClick: EventEmitter<void> = new EventEmitter();
  protected partColor = '#16adb4';
  protected totalColor = 'rgba(22, 173, 180, 0.3)';
  private breakpointSubscription: Subscription = new Subscription();

  constructor(private hoursOverviewService: HoursOverviewService,
              private breakpointObserver: BreakpointObserver
  ) {
    this.breakpointSubscription = breakpointObserver.observe('(max-width: 767px)')
      .subscribe(({ matches }) => this.isMobile = matches);

    this.circles.push(hoursOverviewService.getHoursInProject());
    this.circles.push(hoursOverviewService.getHoursInBundle());
  }

  public ngOnDestroy(): void {
    this.breakpointSubscription.unsubscribe();
  }
}
