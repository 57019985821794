import { Component, Input, TemplateRef } from '@angular/core';
import { Project } from '@core/models/project.model';
import { ProjectDetailCard } from '@core/models/project-detail-card.model';

@Component({
  selector: 'app-dashboard-project-details-card',
  templateUrl: './project-details-card.component.html',
  styleUrls: ['./project-details-card.component.scss']
})
export class ProjectDetailsComponent {
  @Input()
  public project?: Project;
  @Input()
  public buttonIconHeader: string;
  @Input()
  public buttonLabelHeader: string;
  @Input()
  public projectDetailCards: ProjectDetailCard[];
  @Input()
  public title: string;
  @Input()
  public detailCardLabel: string;
  @Input()
  public progressbarLabel: string;
  @Input()
  public progressBarValue?: object[];
  @Input()
  public footerTemplate: TemplateRef<string>;

  public determineWidth(progressbar: number): number {
    if (progressbar > 80) {
      return 80;
    } else if (progressbar < 20) {
      return 20;
    }
    return progressbar;
  }

  public getProgressbarStyle(progressbar: number, additionalStyle?: object): any {
    return {
      width: (this.determineWidth(progressbar)) + '%',
      ...additionalStyle
    };
  }
}
