import { Injectable } from '@angular/core';
import { ApiService } from '@emendis/api';
import { Faq } from '@core/models/helpdesk/faq.model';
import { Observable } from '@node_modules/rxjs';
import { Endpoints } from '@core/enums/generic/endpoints.enum';

@Injectable({
  providedIn: 'root'
})
export class FaqService extends ApiService<Faq> {
  protected endpoint = Endpoints.faq;

  public fetchFaq(): Observable<any> {
    return this.apiHttp.get(`/${this.endpoint}`);
  }
}
