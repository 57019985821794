import { Injectable } from '@angular/core';
import { ApiHttpService, ApiService } from '@emendis/api';
import { Observable } from '@node_modules/rxjs';
import { HttpClient } from '@angular/common/http';
import { ProjectRequests } from '@core/enums/project-requests.enum';
import { Document } from '@core/models/document.model';
import { Endpoints } from '@core/enums/generic/endpoints.enum';

@Injectable({
  providedIn: 'root'
})
export class DocumentService extends ApiService<Document> {
  protected endpoint = Endpoints.document;

  constructor(apiHttp: ApiHttpService, private http: HttpClient) {
    super(apiHttp);
  }

  public getDocuments(projectId: string): Observable<any[]> {
    return this.get('');
  }

  public getUnreadDocuments(projectId: string): Observable<number> {
    return this.get(projectId + ProjectRequests.unreadDocuments);
  }

  public favorite(documentId: string): Observable<any> {
    return this.apiHttp.post(`/${this.endpoint}/${documentId}/favorite`, {});
  }

  public unFavorite(documentId: string): Observable<any> {
    return this.apiHttp.delete(`/${this.endpoint}/${documentId}/favorite`);
  }

  public changeDocumentRead(documentId: string): Observable<any> {
    return this.apiHttp.post(`/${this.endpoint}/${documentId}/read`, {});
  }
}
