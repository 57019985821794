import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Faq } from '@core/models/helpdesk/faq.model';
import { BehaviorSubject, Subject, Subscription } from '@node_modules/rxjs';
import { takeUntil } from '@node_modules/rxjs/internal/operators';

@Component({
  selector: 'app-helpdesk-search',
  templateUrl: './helpdesk-search.component.html',
  styleUrls: ['./helpdesk-search.component.scss']
})
export class HelpdeskSearchComponent implements OnInit, OnDestroy {
  public searchedItems: Faq[] = [];
  public searchResultsLength: number;
  public keys = Object.keys;
  public searchResultsTerm: string = '';
  public faqDataResponse: Faq[];
  public menuOpen: boolean[] = [];
  private _faqData = new BehaviorSubject<Faq[]>([]);
  private destroy$: Subject<boolean> = new Subject<boolean>();

  @Input() set faqData(value: Faq[]) {
    this._faqData.next(value);
  }

  get faqData(): Faq[] {
    return this._faqData.getValue();
  }

  public ngOnInit(): void {
    this._faqData.pipe(takeUntil(this.destroy$))
      .subscribe(response => this.faqDataResponse = response);
  }

  public search(event: any): void {
    this.searchedItems = [];
    this.searchResultsLength = 0;
    this.searchResultsTerm = event.target.value.toLowerCase();

    if (this.faqDataResponse) {
      this.searchedItems = this.faqDataResponse.filter((faq) =>
        faq.question.toLowerCase().indexOf(this.searchResultsTerm) !== -1);
    }
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
  }
}
