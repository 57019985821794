import { Injectable } from '@angular/core';
import { ApiIndexResult, ApiService } from '@emendis/api';
import { Observable } from '@node_modules/rxjs';
import 'rxjs-compat/add/operator/map';
import { Group } from '@core/models/profile/group.model';
import { GeneralData } from '@core/models/profile/general-data.model';

@Injectable({
  providedIn: 'root'
})

export class GroupService extends ApiService<Group> {
  protected endpoint = 'group';

  public getGroupDataByGroupID(groupID: string): Observable<string> {
    return this.get(groupID, {
      include: [
        'addresses'
      ]
    });
  }

  public changeGeneralData(data: {}): Observable<ApiIndexResult<GeneralData>> {
    return this.update(data, {
      include: [
        'addresses'
      ]
    });
  }
}
