import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@emendis/auth';
import { loginRoutes } from '@emendis/login';
import { EmendisRoutes } from '@core/enums/generic/routes.enum';
import { BasicLayoutComponent } from '@features/layouts/basic-layout/basic-layout.component';
import { CoreModule, IndexedDbGuard } from '@emendis/complete';
import { CompleteResolver } from '@emendis/complete';


const routes: Routes = [
  {
    path: 'translation',
    loadChildren: () => CoreModule
  },
  {
    path: EmendisRoutes.admin,
    component: BasicLayoutComponent,
    canActivate: [AuthGuard, IndexedDbGuard],
    resolve: {
      complete: CompleteResolver,
    },
    data: {
      permissions: {
        only: 'myemendis.client.view'
      },
    },
    children: [
      {
        path: EmendisRoutes.project,
        loadChildren: './features/project/project.module#ProjectModule',
      },
      {
        path: EmendisRoutes.projects,
        loadChildren: './features/project/pages/select-project/select-project.module#SelectProjectModule'
      },
      {
        path: '**',
        redirectTo: `${EmendisRoutes.projects}`,
      }
    ]
  },
  {
    path: EmendisRoutes.project_manager,
    component: BasicLayoutComponent,
    canActivate: [AuthGuard, IndexedDbGuard],
    data: {
      permissions: {
        only: 'myemendis.projectmanager.view'
      },
    },
    resolve: {
      complete: CompleteResolver,
    },
    children: [
      {
        path: EmendisRoutes.project,
        loadChildren: './features/project-manager/project-manager.module#ProjectManagerModule'
      },
      {
        path: EmendisRoutes.projects,
        loadChildren: './features/project-manager/pages/select-manager-project/select-manager-project.module#SelectManagerProjectModule'
      },
      {
        path: '**',
        redirectTo: `${EmendisRoutes.projects}`,
      }
    ]
  },
  ...loginRoutes,
  {
    path: '**',
    redirectTo: `${EmendisRoutes.auth}/${EmendisRoutes.login}`
  }
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
