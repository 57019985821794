import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { DialogService } from '@node_modules/primeng/components/dynamicdialog/dialogservice';
import { ProjectService } from '@shared/services/project.service';
import { SelectedProject } from '@core/models/selected-project.model';
import { Subject, Subscription } from '@node_modules/rxjs';
import { takeUntil } from '@node_modules/rxjs/internal/operators';
import { HelpdeskTicketFormComponent } from '@shared/components/helpdesk-ticket-form/helpdesk-ticket-form.component';
import { TranslateService } from '@node_modules/@ngx-translate/core';
import { ActivatedRoute, Event, NavigationStart, Router } from '@angular/router';
import { EmendisRoutes } from '@core/enums/generic/routes.enum';
import { AuthService } from '@emendis/auth';
import { BuildURLService } from '@shared/services/build-url.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Input()
  public selectedProject: SelectedProject;
  @Output()
  public openNotificationBar: EventEmitter<void> = new EventEmitter();
  @Output()
  public switchProject: EventEmitter<string> = new EventEmitter();
  public projectsList: SelectItem[];
  public displayHelpdeskButton: boolean = false;
  public displayCreateNewProjectButton: boolean;
  public showProjectLabel: boolean = false;
  public displayClientDropdown: boolean = false;
  public projectManager: string;
  private routePath: Subscription;
  private destroy$: Subject<boolean> = new Subject<boolean>();
  private routeProjects = [EmendisRoutes.admin, EmendisRoutes.projects];

  constructor(
    public translate: TranslateService,
    private projectService: ProjectService,
    private dialogService: DialogService,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private buildURLService: BuildURLService
  ) {
    // Check header buttons every time url changes.
    router.events.pipe(takeUntil(this.destroy$))
      .subscribe((event: Event) => {
        if (event instanceof NavigationStart) {
          this.displayHeaderButtonsOnUrlChange(event.url);
        }
      });
  }

  public ngOnInit(): void {
    this.projectManager = this.authService.getUser().name;
    this.hideDropdown();
    this.redirectToHomepage();
    this.projectService.index().pipe(takeUntil(this.destroy$)).subscribe((response) => {
      this.projectsList = response.data.map((item: SelectedProject) => {
        return { label: item.name, value: item.id };
      });
      if (!this.buildURLService.buildURL(this.routeProjects).includes(this.router.url)) {
        this.routePath = this.route.params.pipe(takeUntil(this.destroy$)).subscribe((event) => {
          this.onChangeClientProjectLabel(this.route.children[0].snapshot.firstChild.params.id);
        });
        this.showProjectLabel = true;
        this.redirectNonExistingRoutes();
        this.displayHeaderButtonsOnInit();
      }
    });


  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
  }

  public displayHeaderButtonsOnInit(): void {
    if (this.router.url.includes(EmendisRoutes.project_manager)) {
      this.displayCreateNewProjectButton = true;
      this.displayHelpdeskButton = false;
    }
    for (let i = 0; i < this.projectsList.length; i++) {
      if (this.router.url.includes(`/${EmendisRoutes.admin}/${EmendisRoutes.project}/${this.projectsList[i].value}`)) {
        this.displayHelpdeskButton = true;
        this.displayCreateNewProjectButton = false;
      }
    }
  }

  public displayHeaderButtonsOnUrlChange(currentRoute: string): void {
    if (currentRoute.includes(`/${EmendisRoutes.project_manager}`)) {
      this.displayCreateNewProjectButton = true;
      this.displayHelpdeskButton = false;
    }
    for (let i = 0; i < this.projectsList.length; i++) {

      if (currentRoute.includes(`/${EmendisRoutes.admin}/${EmendisRoutes.project}/${this.projectsList[i].value}`)) {
        this.displayHelpdeskButton = true;
        this.displayCreateNewProjectButton = false;
      }
    }
  }

  public onChangeClientProjectLabel(projectId: string): void {
    this.projectsList.forEach(item => {
      if (item.value === projectId) {
        this.selectedProject = {
          id: projectId,
          name: item.label
        };

        this.router.navigate([
          EmendisRoutes.admin,
          EmendisRoutes.project,
          this.selectedProject.id,
          EmendisRoutes.info
        ]);
      }
    });
  }

  public onChangeManagerProjectLabel(projectId: string): void {
    this.projectsList.forEach(item => {
      if (item.value === projectId) {
        this.selectedProject = {
          id: projectId,
          name: item.label
        };
        this.router.navigate([
          EmendisRoutes.project_manager,
          EmendisRoutes.project,
          this.selectedProject.id,
          EmendisRoutes.info
        ]);
      }
    });
  }

  public redirectNonExistingRoutes(): void {
    for (let i = 0; i < this.projectsList.length; i++) {
      if (this.router.url.includes(`${EmendisRoutes.admin}`)) {

        if (this.router.url !== `${EmendisRoutes.admin}/${EmendisRoutes.project}/${this.projectsList[i].value}`) {
          this.router.navigate([EmendisRoutes.admin, EmendisRoutes.projects]);
        }
      } else if (this.router.url.includes(`${EmendisRoutes.project_manager}`)) {
        if (this.router.url !== `${EmendisRoutes.project_manager}/${EmendisRoutes.project}/${this.projectsList[i].value}`) {
          this.router.navigate([EmendisRoutes.project_manager, EmendisRoutes.projects]);
        }
      }
    }
  }

  public onOpenNewTicketDialog(): void {
    event.preventDefault();
    this.dialogService.open(HelpdeskTicketFormComponent, {
      header: this.translate.instant('headers.new-helpdeskticket'),
      styleClass: 'helpdesk-ticket-form',
      style: {
        overflowY: 'scroll',
        background: 'white',
        padding: '20px'
      },
      dismissableMask: true
    });
  }

  public openNewProject(): void {
    this.router.navigate([EmendisRoutes.project_manager, EmendisRoutes.project, EmendisRoutes.new_project]);
  }

  public hideDropdown(): void {
    this.displayClientDropdown = !this.router.url.includes(EmendisRoutes.project_manager);
  }

  public redirectToHomepage(): string {
    let route: string;
    if (this.router.url.includes(`${EmendisRoutes.admin}`)) {

      return route = `${EmendisRoutes.admin}/${EmendisRoutes.projects}`;
    } else {

      return route = `${EmendisRoutes.project_manager}/${EmendisRoutes.projects}`;
    }
  }
}
