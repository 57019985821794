import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProjectManagerRoutingModule } from './project-manager-routing.module';
import { ManagerRootComponent } from './manager-root/manager-root.component';
import {
  EmendisCardModule,
  EmendisPrimeButtonModule,
  EmendisPrimeFileUploadModule,
  EmendisTableModule,
  EmendisUiModule
} from '@emendis/ui';
import { TranslateModule } from '@node_modules/@ngx-translate/core';
import { ManagerManualComponent } from './components/manager-manual/manager-manual.component';
import { ProjectModule } from '@features/project/project.module';
import { ManagerHelpdeskTicketWidgetComponent } from './components/manager-helpdesk-ticket-widget/manager-helpdesk-ticket-widget.component';
import { ProjectsTableComponent } from './components/projects-table/projects-table.component';
import { NewProjectComponent } from './pages/new-project/new-project.component';
import { ProjectDataFormComponent } from './components/new-project/project-data-form/project-data-form.component';
import { BillingAddressFormComponent } from './components/new-project/billing-address-form/billing-address-form.component';
import { CompanyAddressFormComponent } from './components/new-project/company-address-form/company-address-form.component';
import { SharedModule } from '@shared/shared.module';
import { FormInputComponent } from './components/new-project/form-input/form-input.component';
import { FormDropdownComponent } from './components/new-project/form-dropdown/form-dropdown.component';
import { ManualsManagementComponent } from './pages/manuals-management/manuals-management.component';
import { ManualFormComponent } from './components/manual-form/manual-form.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { ProjectComponent } from './pages/project/project.component';
import { DocumentsComponent } from './pages/documents/documents.component';
import { HelpdeskComponent } from './pages/helpdesk/helpdesk.component';
import { ProfileComponent } from './pages/profile/profile.component';

@NgModule({
  declarations: [
    ManagerRootComponent,
    ManagerManualComponent,
    ManagerHelpdeskTicketWidgetComponent,
    ProjectsTableComponent,
    NewProjectComponent,
    ProjectDataFormComponent,
    BillingAddressFormComponent,
    CompanyAddressFormComponent,
    FormInputComponent,
    FormDropdownComponent,
    ManualsManagementComponent,
    ManualFormComponent,
    DashboardComponent,
    ProjectComponent,
    DocumentsComponent,
    HelpdeskComponent,
    ProfileComponent
  ],
  imports: [
    CommonModule,
    ProjectManagerRoutingModule,
    EmendisPrimeButtonModule,
    EmendisCardModule,
    TranslateModule,
    EmendisTableModule,
    EmendisUiModule,
    ProjectModule,
    SharedModule,
    EmendisPrimeFileUploadModule
  ],
  exports: [
    ManagerManualComponent,
    ManagerHelpdeskTicketWidgetComponent,
    ProjectsTableComponent
  ]
})
export class ProjectManagerModule {
}
