import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Subscription } from '@node_modules/rxjs';
import { Faq } from '@core/models/helpdesk/faq.model';
import { SelectItem } from 'primeng/api';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit, OnDestroy {
  @Input()
  public selection: SelectItem[];
  public selectedFilter: string;
  public faqDataResponse: Faq[];
  public groupedFaqs: Faq[] = [];
  private _faqData = new BehaviorSubject<Faq[]>([]);
  private faqSubscription: Subscription;

  @Input() set faqData(value: Faq[]) {
    this._faqData.next(value);
  }

  get faqData(): Faq[] {
    return this._faqData.getValue();
  }

  public ngOnInit(): void {
    this.faqSubscription = this._faqData.subscribe(response => {
      this.faqDataResponse = response;
      if (this.faqDataResponse) {
        this.groupedFaqs = this.groupFaq();
      }
    });
  }

  public groupFaq(): any {
    const groupedFaqs = {};
    for (const faq of this.faqDataResponse) {
      groupedFaqs[faq.faq_category_base_data_value_id] = [...(groupedFaqs[faq.faq_category_base_data_value_id] || []), faq];
    }
    return groupedFaqs;
  }

  public ngOnDestroy(): void {
    this.faqSubscription.unsubscribe();
  }
}
