import { Component, EventEmitter, Input, Output, } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { GeneralData } from '@core/models/profile/general-data.model';

@Component({
  selector: 'app-profile-general',
  templateUrl: './profile-general.component.html',
  styleUrls: ['./profile-general.component.scss']
})
export class ProfileGeneralComponent {
  @Input()
  set informationCard(value: GeneralData) {
    this._informationCard = value;
    this.form = this.formBuilder.group({
      imageUrl: [''],
      is_billing: [value.is_billing],
      address: this.formBuilder.group({
        zipcode: [value.zip_code],
        housenumber: [value.house_number],
        street: [value.street],
        city: [value.city],
        country: [value.country],
        phone: [value.phone_number],
        email: [value.email],
      }),
      billingAddress: this.formBuilder.group({
        addressline_1: [value.billing_company_name],
        zipcode: [value.billing_zip_code],
        housenumber: [value.house_number],
        street: [value.street],
        city: [value.billing_city],
        country: [value.billing_country],
      })
    });
  }

  get informationCard(): GeneralData {
    return this._informationCard;
  }
  public form;
  public generalDataEditable: boolean = false;
  @Output()
  public changeGeneralData: EventEmitter<any> = new EventEmitter();
  private isBillingChanged: boolean = false;
  private _informationCard: GeneralData;

  constructor(private formBuilder: FormBuilder) {
  }

  public isPaddingNeeded(): string {
    if (this.generalDataEditable) {
      return 'mb-0';
    } else {
      return 'mb-3';
    }
  }

  public editGeneralData(): void {
    this.generalDataEditable = true;
    this.isBillingChanged = false;
    this.form.get('is_billing').setValue(this.informationCard.is_billing);
  }

  public changeBillingAddressValue(): void {
    this.informationCard.is_billing = !this.informationCard.is_billing;
    this.isBillingChanged = true;
  }

  public cancelGeneralDataEdit(): void {
    this.generalDataEditable = false;
    if (this.isBillingChanged) {
      this.informationCard.is_billing = !this.informationCard.is_billing;
    }
  }

  public saveGeneralData(): void {
    this.changeGeneralData.emit(this.form.value);
    this.generalDataEditable = false;
  }
}
