import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectManagerProjectComponent } from './select-manager-project.component';
import { SelectManagerProjectRoutingModule } from './select-manager-project-routing.module';
import { ProjectManagerModule } from '@features/project-manager/project-manager.module';

@NgModule({
  declarations: [SelectManagerProjectComponent],
  imports: [
    CommonModule,
    SelectManagerProjectRoutingModule,
    ProjectManagerModule
  ]
})
export class SelectManagerProjectModule {
}
