import { NotificationIcons } from '@emendis/ui';
import { Colors } from '@core/enums/Colors.enum';

export class NotificationIconTypes {
  public readonly appointmentReject = { icon: NotificationIcons.appointment, iconColor: Colors.rejectNotification };
  public readonly appointmentApprove = { icon: NotificationIcons.appointment, iconColor: Colors.defaultNotification };
  public readonly document = { icon: NotificationIcons.file, iconColor: Colors.defaultNotification };
  public readonly success = { icon: NotificationIcons.success, iconColor: Colors.defaultNotification };
  public readonly warning = { icon: NotificationIcons.warning, iconColor: Colors.defaultNotification };
  public readonly error = { icon: NotificationIcons.error, iconColor: Colors.defaultNotification };
}
