import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-card-question',
  templateUrl: './card-questions.component.html',
  styleUrls: ['./card-questions.component.scss']
})
export class CardQuestionsComponent {
  @Input()
  public faqData;
  @Input()
  public category;
  public menuOpen: boolean[] = [];
}
