import { Injectable } from '@angular/core';
import { ApiIndexResult, ApiService, ListOptions } from '@emendis/api';
import { Profile } from '@core/models/profile/profile.model';
import { Observable } from '@node_modules/rxjs';
import * as faker from 'faker';
import { UserRole } from '@core/enums/profile/user-role.enum';
import { UserIcon } from '@core/enums/profile/user-icon.enum';
import { Endpoints } from '@core/enums/generic/endpoints.enum';

@Injectable({
  providedIn: 'root'
})
export class ProfileService extends ApiService<Profile> {
  public userRole = UserRole;
  public userIcon = UserIcon;
  public currentRole: string;
  public currentIcon: string;
  public displayUsers: {}[] = [];
  protected endpoint = Endpoints.profile;

  // TODO send to back-end when ready
  public getSettingsData(data: {}): void {
  }

  // TODO send to back-end when ready.
  public getNewUserData(data: {}): void {
  }

  public getFakeData<T = Profile>(options?: ListOptions): Observable<ApiIndexResult<T>> {
    const mainData = {
      id: faker.random.uuid(),
      settings_data: {
        fullName: faker.name.findName(),
        emailAddress: faker.internet.email(),
        languageSettings: faker.address.country(),
        password: faker.name.findName(),
        emailNotifications: faker.random.boolean()
      },
      display_users: this.displayUsersFunc(),
    };

    return Observable.create(observer => {
      observer.next({ data: mainData });
      observer.complete();
    });
  }

  public generateRoles(): string {
    // @ts-ignore
    const keys = Object.values(this.userRole);
    this.currentRole = keys[Math.floor(Math.random() * keys.length)];
    return this.currentRole;
  }

  public generateIcon(): string {
    if (this.currentRole === 'profile.role-admin') {
      return this.currentIcon = this.userIcon.userCrown;
    } else {
      return this.currentIcon = this.userIcon.user;
    }
  }

  public displayUsersFunc(): any {
    this.displayUsers = [];
    for (let i = 0; i < 10; i++) {
      this.displayUsers.push({
        id: faker.random.uuid(),
        name: faker.name.findName(),
        email: faker.internet.email(),
        role: this.generateRoles(),
        icon: this.generateIcon()
      });
    }
    return this.displayUsers;
  }
}
