import { Component, DoCheck, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { Document } from '@core/models/document.model';
import { range } from 'lodash';
import { SortDirection } from '@core/enums/sort-direction.enum';

@Component({
  selector: 'app-documents-overview-card',
  templateUrl: './documents-overview-card.component.html',
  styleUrls: ['./documents-overview-card.component.scss']
})
export class DocumentsOverviewCardComponent implements OnChanges, DoCheck {
  @Input()
  public documents: Document[] = [];
  @Input()
  public searchIcon: string = 'fas fa-search';
  @Input()
  public searchPlaceHolder: string = 'general.search.placeholder';
  @Input()
  public filterDocuments: Document[];
  @Output()
  public onFavorite: EventEmitter<Document> = new EventEmitter<Document>();
  @Output()
  public onOpenDocument: EventEmitter<Document> = new EventEmitter<Document>();
  public pageDocuments: Document[];
  public range: Function = range;
  public SortDirection: typeof SortDirection = SortDirection;
  public currentPage: number = 0;
  public nPages: number;
  public nameSortDirection: SortDirection = SortDirection.asc;
  public dateSortDirection: SortDirection = SortDirection.desc;
  private itemsPerPage = 9;

  public ngOnChanges(): void {
    this.filterDocuments = this.documents || [];
  }

  public search(event: any): void {
    this.filterDocuments = this.documents.filter((document) => {
      return document.filename.toLowerCase().indexOf(event.target.value.toLowerCase()) !== -1;
    });
  }

  public isCurrentPage(pageNr: number): boolean {
    return this.currentPage === pageNr;
  }

  public setCurrentPageNr(pageNr: number): void {
    if (pageNr >= 0 && pageNr < this.nPages) {
      this.currentPage = pageNr;
    }
  }

  public sortAlphabetically(sortDirection: SortDirection): void {
    const filteredDocuments = this.filterDocuments.sort((doc1, doc2) => doc1.filename.localeCompare(doc2.filename));
    this.filterDocuments = sortDirection === SortDirection.asc ? filteredDocuments.reverse() : filteredDocuments;
    this.nameSortDirection = this.nameSortDirection === SortDirection.asc ? SortDirection.desc : SortDirection.asc;
  }

  public sortDates(sortDirection: SortDirection): void {
    const filteredDocuments = this.filterDocuments.sort((doc1, doc2) => {
      return new Date(doc2.created_at).getTime() - new Date(doc1.created_at).getTime();
    });
    this.filterDocuments = sortDirection === SortDirection.asc ? filteredDocuments.reverse() : filteredDocuments;
    this.dateSortDirection = this.dateSortDirection === SortDirection.asc ? SortDirection.desc : SortDirection.asc;
  }

  public updatePage(): void {
    const start = this.currentPage * this.itemsPerPage;
    this.pageDocuments = this.filterDocuments.filter(doc => doc.is_read).slice(start, start + this.itemsPerPage);
    this.nPages = Math.ceil(this.filterDocuments.length / this.itemsPerPage);
  }

  public getUnreadDocs(): Document[] {
    return this.filterDocuments.filter(doc => !doc.is_read);
  }

  public ngDoCheck(): void {
    this.updatePage();
  }
}
