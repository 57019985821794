import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Document } from '@core/models/document.model';

@Component({
  selector: 'app-document-detail-row',
  templateUrl: './document-detail-row.component.html',
  styleUrls: ['./document-detail-row.component.scss']
})
export class DocumentDetailRowComponent {
  @Input()
  public document: Document;
  @Input()
  public isFavoriteAble: boolean;
  @Input()
  public icon: string = 'far fa-file';
  @Output()
  public onOpenDocument: EventEmitter<string> = new EventEmitter<string>();
  @Output()
  public onFavorite: EventEmitter<Document> = new EventEmitter<Document>();
}
