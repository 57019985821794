import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-project-detail-card',
  templateUrl: './project-detail-card.component.html',
  styleUrls: ['./project-detail-card.component.scss']
})
export class ProjectDetailCardComponent {
  @Input()
  public icon: string;
  @Input()
  public text: string;
  @Input()
  public data: number;
}
