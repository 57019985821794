import { Component, OnInit } from '@angular/core';
import { TicketService } from '@shared/services/helpdesk/ticket.service';
import { first } from '@node_modules/rxjs/internal/operators';
import { Helpdeskticket } from '@shared/models/helpdeskticket.model';
import { HelpdeskTicketOverviewComponent } from '@shared/components/helpdesk-ticket-overview/helpdesk-ticket-overview.component';
import { DialogService } from '@node_modules/primeng/components/dynamicdialog/dialogservice';
import { TranslateService } from '@node_modules/@ngx-translate/core';

@Component({
  selector: 'app-manager-helpdesk-ticket-widget',
  templateUrl: './manager-helpdesk-ticket-widget.component.html',
  styleUrls: ['./manager-helpdesk-ticket-widget.component.scss']
})
export class ManagerHelpdeskTicketWidgetComponent implements OnInit {
  public tickets: Helpdeskticket[];
  private readonly dialogStyle: {} = {
    overflowY: 'scroll',
    background: 'white',
    padding: '20px',
    maxHeight: '80vh'
  };

  constructor(
    private translateService: TranslateService,
    private ticketService: TicketService,
    private dialogService: DialogService
  ) {
  }

  public ngOnInit(): void {
    this.ticketService.index().pipe(first())
      .subscribe(response => this.tickets = response.data);
  }

  public openTicketDialog(ticket: Helpdeskticket): void {
    this.dialogService.open(HelpdeskTicketOverviewComponent, {
      header: this.translateService.instant('headers.helpdeskticket'),
      data: ticket,
      style: this.dialogStyle,
      styleClass: 'helpdesk-ticket-overview',
      dismissableMask: true
    });
  }
}
