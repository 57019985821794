import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DocumentService } from '@shared/services/document.service';
import { Document } from '@core/models/document.model';
import { Subject } from '@node_modules/rxjs';
import { PDFDialogService } from '@shared/services/pdfdialog.service';
import { ProjectService } from '@shared/services/project.service';
import { EmendisRoutes } from '@core/enums/generic/routes.enum';
import { Router } from '@angular/router';
import { FileService } from '@shared/services/file.service';

@Component({
  selector: 'app-document-list',
  templateUrl: './document-list.component.html',
  styleUrls: ['./document-list.component.scss']
})

export class DocumentListComponent implements OnInit, OnDestroy {
  @Input()
  public projectId: string;
  public documents: Document[] = [];
  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private documentService: DocumentService,
    private projectService: ProjectService,
    private fileService: FileService,
    private router: Router,
    private pdfDialogService: PDFDialogService) {
  }

  public ngOnInit(): void {
    this.documents = [];

    this.projectService.getDocuments(this.projectId)
      .subscribe(project => {
        this.documents = project.documents;
      });
  }

  public onOpenDocument(document: Document): void {
    this.fileService.download(document.file.id).subscribe(blob => {
      const reader = new FileReader();
      reader.readAsArrayBuffer(blob);
      reader.onloadend = () => {
        this.pdfDialogService.open(
          {
            filename: document.file.filename,
            src: { data: reader.result }
          }
        );
      };
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
  }

  public redirectToDocuments(): void {
    this.router.navigate(
      [EmendisRoutes.admin, EmendisRoutes.project, this.projectId, 'documents']
    );
  }
}
