import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BuildURLService {

  public buildURL(urls: string[]): string {
    return urls.join();
  }
}
