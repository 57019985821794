import { Injectable } from '@angular/core';
import { ApiIndexResult, ApiService, ListOptions } from '@emendis/api';
import { Helpdeskticket } from '@shared/models/helpdeskticket.model';
import { BehaviorSubject, Observable } from '@node_modules/rxjs';
import { TicketType } from '@core/enums/helpdesk/ticket-type.enum';
import { BrowserType } from '@core/enums/helpdesk/browser-type.enum';
import { Endpoints } from '@core/enums/generic/endpoints.enum';

@Injectable({
  providedIn: 'root'
})
export class HelpdeskFormService extends ApiService<Helpdeskticket> {
  public mainData;
  public messageSource = new BehaviorSubject('');
  public currentMessage = this.messageSource.asObservable();
  protected endpoint = Endpoints.helpdesk;

  public index<T = Helpdeskticket>(options?: ListOptions): Observable<ApiIndexResult<T>> {
    this.mainData = {
      ticketTypes: [
        { label: TicketType.bug, value: TicketType.bug },
        { label: TicketType.newFunctionality, value: TicketType.newFunctionality },
        { label: TicketType.openEndedQuestion, value: TicketType.openEndedQuestion }
      ],
      usedBrowser: [
        { label: BrowserType.chrome, value: BrowserType.chrome },
        { label: BrowserType.safari, value: BrowserType.safari },
        { label: BrowserType.firefox, value: BrowserType.firefox }
      ],
      inputData: {},
    };
    return new Observable(observer => {
      observer.next({ data: this.mainData });
      observer.complete();
    });
  }

  // Browser detection with User Agent. Malicious users can turn off User Agent.
  public detectBrowser(): BrowserType {
    if (navigator.userAgent.indexOf('Chrome') !== -1) {
      return BrowserType.chrome;

    } else if (navigator.userAgent.indexOf('Firefox') !== -1) {
      return BrowserType.firefox;

    } else if (navigator.userAgent.indexOf('MSIE') !== -1) {
      return BrowserType.explorer;

    } else if (navigator.userAgent.indexOf('Edge') !== -1) {
      return BrowserType.edge;

    } else if (navigator.userAgent.indexOf('Safari') !== -1) {
      return BrowserType.safari;

    } else if (navigator.userAgent.indexOf('Opera') !== -1) {
      return BrowserType.opera;

    } else {
      return BrowserType.unknown;
    }
  }

  public getFormData(data: any): void {
    this.messageSource.next(data);
  }
}
