import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { ProjectRootComponent } from './project-root/project-root.component';
import { ProjectComponent } from '@features/project/pages/project/project.component';
import { DocumentsComponent } from '@features/project/pages/documents/documents.component';
import { EmendisRoutes } from '@core/enums/generic/routes.enum';
import { HelpdeskComponent } from '@features/project/pages/helpdesk/helpdesk.component';
import { ProfileComponent } from './pages/profile/profile.component';

const routes: Routes = [
  {
    path: ':id',
    component: ProjectRootComponent,
    children: [
      {
        path: `${EmendisRoutes.info}`,
        component: ProjectComponent
      },
      {
        path: `${EmendisRoutes.dashboard}`,
        component: DashboardComponent
      },
      {
        path: `${EmendisRoutes.documents}`,
        component: DocumentsComponent
      },
      {
        path: `${EmendisRoutes.helpdesk}`,
        component: HelpdeskComponent
      },
      {
        path: `${EmendisRoutes.profile}`,
        component: ProfileComponent
      },
      {
        path: '**',
        redirectTo: `${EmendisRoutes.projects}`,
      }
    ]
  },
  {
    path: '**',
    redirectTo: `/${EmendisRoutes.admin}/${EmendisRoutes.projects}`,
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class ProjectRoutingModule {
}
