import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ProjectService } from '@shared/services/project.service';
import { takeUntil } from '@node_modules/rxjs/internal/operators';
import { Subject } from '@node_modules/rxjs';

@Component({
  selector: 'app-billing-address-form',
  templateUrl: './billing-address-form.component.html',
})
export class BillingAddressFormComponent implements OnInit, OnDestroy {
  @Output()
  public onBillingDataSubmit: EventEmitter<any> = new EventEmitter();
  public menuVisible: boolean = true;
  public options = this.projectService.billingAddressCountryOptions;
  public submitData: boolean = true;
  public form;
  private destroy = new Subject<void>();

  constructor(
    private formBuilder: FormBuilder,
    private projectService: ProjectService
  ) {
  }

  public ngOnInit(): void {
    this.buildForm();
    this.projectService.submitNewProject.pipe(takeUntil(this.destroy))
      .subscribe(() => {
        this.submitForm();
      });

    this.projectService.submitSameAsCompany.pipe(takeUntil(this.destroy))
      .subscribe((value) => {
        this.hideInputs(value);
      });

    this.projectService.resetAllForms.pipe(takeUntil(this.destroy))
      .subscribe((() => {
        this.resetForm();
      }));
  }

  public buildForm(): void {
    this.form = this.formBuilder.group({
      company_name: ['', [Validators.required]],
      zip_code: ['', [Validators.required]],
      house_number: ['', [Validators.required]],
      street: ['', [Validators.required]],
      city: ['', [Validators.required]],
      country: ['France'],
      email: ['', [Validators.required, Validators.email]],
      company_phone: ['', [Validators.required]],
    });
  }

  public submitForm(): void {
    if (this.submitData) {
      this.onBillingDataSubmit.emit(this.form);
    }
  }

  public hideInputs(value: boolean): void {
    if (value === true) {
      this.submitData = true;
    }
    this.submitData = value !== true;
    this.menuVisible = value !== true;
  }

  public hideDropdown(): void {
    if (this.submitData) {
      this.menuVisible = !this.menuVisible;
    }
  }

  public resetForm(): void {
    this.form.reset();
  }

  public ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }
}
