import { BUILD_TIMESTAMP } from '@environments/timestamp';
const packageJson = require('../../package.json');

export const environment = {
  appName: 'MyEmendis - Test',
  production: false,
  hmr: false,
  name: 'Test',
  databaseName: 'emendis.complete.myemendis',
  databaseVersion: 1,
  baseUrl: 'https://myemendis.complete.test.emendis.nl/api',
  sentryUrl: null,
  version: packageJson.version + '.' + BUILD_TIMESTAMP
};
