import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ManualService } from '@shared/services/manual.service';
import { Manual } from '@core/models/manual.model';

@Component({
  selector: 'app-manuals-management',
  templateUrl: './manuals-management.component.html',
  styleUrls: ['./manuals-management.component.scss']
})
export class ManualsManagementComponent implements OnInit {
  public form: FormGroup;
  public manualInfo: Manual[];

  constructor(private manualService: ManualService) {
  }

  public ngOnInit(): void {
    if (this.manualService) {
      this.manualInfo = this.manualService.manualInfo;
    }
  }

  public onSubmit(formData: any): void {
    this.manualService.onFormSubmitted.emit(formData);
  }
}
