import { Injectable } from '@angular/core';
import { ApiService } from '@emendis/api';
import { Invoice } from '@core/models/invoice.model';
import { Endpoints } from '@core/enums/generic/endpoints.enum';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService extends ApiService<Invoice> {
  protected endpoint = Endpoints.invoice;
}
