import { Component, Input, OnInit } from '@angular/core';
import { ManualService } from '@shared/services/manual.service';
import { Manual } from '@core/models/manual.model';
import { Icons } from '@core/enums/generic/icons.enum';
import { PDFDialogService } from '@shared/services/pdfdialog.service';
import { FileService } from '@shared/services/file.service';

@Component({
  selector: 'app-default-manuals',
  templateUrl: './default-manuals.component.html',
  styleUrls: ['./default-manuals.component.scss']
})
export class DefaultManualsComponent implements OnInit {
  public iconEnum = Icons;
  @Input()
  public title: string;
  @Input()
  public buttonIconHeader: string;
  @Input()
  public buttonLabelHeader: string;
  public manuals: Manual[] = [];
  private icons: string[] = [this.iconEnum.Emendis, this.iconEnum.Jira, this.iconEnum.Myemendis];

  constructor(
    private manualService: ManualService,
    private fileService: FileService,
    private pdfDialogService: PDFDialogService) {
  }

  public ngOnInit(): void {
    this.manualService.fetchManualDocuments().subscribe((response) => {
      this.manuals = response.data;
      for (let i = 0; i < this.icons.length; i++) {
        this.manuals[i].icon = this.icons[i];
      }
    });
  }

  public onOpenManual(manual: Manual): void {
    this.fileService.fetchFile(manual.files[0].id).subscribe((response) => {
      this.pdfDialogService.open(
        {
          filename: manual.files[0].filename,
          src: { data: atob(response.data.file) },
          fileId: manual.files[0].id
        }
      );
    });
  }
}
