import { Injectable } from '@angular/core';
import { ApiService } from '@emendis/api';
import { Observable } from '@node_modules/rxjs';
import { FileIndexedDbModel } from '@emendis/complete';
import { fromPromise } from '@node_modules/rxjs/internal/observable/fromPromise';
import { map } from 'rxjs/operators';
import { Endpoints } from '@core/enums/generic/endpoints.enum';

@Injectable({
  providedIn: 'root'
})
export class FileService extends ApiService<File> {
  protected endpoint = Endpoints.file;

  public fetchFile(id: string): Observable<any> {
    return this.apiHttp.get(`/${this.endpoint}/${id}`);
  }

  public download(id: string): Observable<Blob> {
    return this.apiHttp.getBlob(`/${this.endpoint}/${id}/download`, null);
  }

  public downloadLocal(id: string): Observable<ArrayBuffer> {
    return fromPromise(FileIndexedDbModel.query().get(id)).pipe(map((file) => file.data));
  }
}
