import { Injectable } from '@angular/core';
import { DialogService } from '@node_modules/primeng/components/dynamicdialog/dialogservice';
import { PDF } from '@core/models/pdf.model';
import { PdfviewerComponent } from '@shared/components/pdfviewer/pdfviewer.component';

@Injectable({
  providedIn: 'root'
})
export class PDFDialogService {
  constructor(private dialogService: DialogService) {
  }

  public open(pdf: PDF, width: string = '41%', height: string = '100%'): void {
    this.dialogService.open(PdfviewerComponent, {
      data: { document: pdf },
      header: pdf.filename,
      styleClass: 'myem-pdfviewer',
      width,
      height
    });
  }

}
