import { Component, Input } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { SettingsData } from '@core/models/profile/settings-data.model';
import { ProfileService } from '@shared/services/profile.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-profile-settings',
  templateUrl: './profile-settings.component.html',
  styleUrls: ['./profile-settings.component.scss']
})
export class ProfileSettingsComponent {
  @Input() public informationCard: SettingsData;
  @Input() public selectLanguage: any;
  public settingsEditable: boolean = false;
  public form = this.formBuilder.group({
    fullName: [''],
    email: ['', [Validators.email]],
    languageSettings: [1],
    password: ['', [Validators.minLength(6)]],
    password_confirmation: [''],
    receiveNotifications: [true]
  }, { validators: this.checkPassword });

  constructor(
    private formBuilder: FormBuilder,
    private profileService: ProfileService,
    private router: Router
  ) {
  }

  public isPaddingNeeded(): string {
    if (this.settingsEditable) {
      return 'mb-0';
    } else {
      return 'mb-3';
    }
  }

  public editSettings(): void {
    this.settingsEditable = true;
  }

  public cancelSettings(): void {
    this.settingsEditable = false;
  }

  public checkSettings(): object {
    const dirtyValues = {};

    Object.keys(this.form.controls).forEach(control => {
      const currentControl = this.form.get(control);

      if (currentControl.dirty) {
        dirtyValues[control] = currentControl.value;
      }
    });
    return dirtyValues;
  }

  public saveSettings(): void {
    const dirtyValues = this.checkSettings();

    Object.keys(dirtyValues).forEach((key) => {
      if (dirtyValues[key] !== '') {
        this.form[key] = dirtyValues[key];
      }
    });
    if (this.checkPassword(this.form.value)) {
      this.profileService.getSettingsData(dirtyValues);
      this.settingsEditable = false;
    }
  }

  public maskPassword(): string {
    const split = this.informationCard.password.split('');
    let result = '';

    for (let i = 0; i < split.length; i++) {
      result += '*';
    }

    return result;
  }

  public logOut(): void {
    localStorage.clear();
    this.router.navigate(['auth/login']);
  }

  public checkPassword(value: any): boolean {
    return value.password === value.confirm_password;
  }
}
