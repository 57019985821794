import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig } from '@node_modules/primeng/components/dynamicdialog/dynamicdialog-config';
import { saveAs } from 'file-saver';
import { PDF } from '@core/models/pdf.model';
import { FileService } from '@shared/services/file.service';

@Component({
  templateUrl: './pdfviewer.component.html'
})
export class PdfviewerComponent implements OnInit {
  public pdf: PDF;

  constructor(public config: DynamicDialogConfig, private fileService: FileService) {
  }

  public ngOnInit(): void {
    this.pdf = this.config.data.document;
  }

  public download(): void {
    this.fileService.download(this.pdf.fileId).subscribe(result => {
      saveAs(result, this.pdf.filename);
    });
  }

}
