import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmendisCardModule, EmendisPrimeButtonModule, EmendisPrimeDropdownModule, EmendisPrimeNotificationBarModule } from '@emendis/ui';
import { HeaderComponent } from './components/header/header.component';
import { SharedModule } from '@shared/shared.module';
import { NotificationBarComponent } from '@core/components/notification-bar/notification-bar.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    HeaderComponent,
    NotificationBarComponent
  ],
  exports: [
    HeaderComponent,
    EmendisCardModule,
    NotificationBarComponent
  ],
  imports: [
    CommonModule,
    EmendisCardModule,
    EmendisPrimeButtonModule,
    EmendisPrimeDropdownModule,
    SharedModule,

    EmendisPrimeNotificationBarModule,
    RouterModule
  ]
})
export class CoreModule {
}
