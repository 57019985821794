import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CoreModule } from '@emendis/complete';
import { CommonModule } from '@angular/common';
import { DocumentListComponent } from '@shared/components/document-list/document-list.component';
import {
  EmendisCardModule,
  EmendisEditorModule,
  EmendisFormFieldModule,
  EmendisImageViewerModule,
  EmendisPdfViewerModule,
  EmendisPrimeFileUploadModule, EmendisPrimeMobileTabBarModule,
  EmendisUiModule, HorizontalNavbarModule
} from '@emendis/ui';
import { DynamicDialogModule } from '@node_modules/primeng/components/dynamicdialog/dynamicdialog';
import { DialogService } from '@node_modules/primeng/components/dynamicdialog/dialogservice';
import { PdfviewerComponent } from '@shared/components/pdfviewer/pdfviewer.component';
import { YourProjectManagerComponent } from './components/your-projectmanager/your-projectmanager.component';
import { DocumentDetailRowComponent } from './components/document-list/document-detail-row/document-detail-row.component';
import { HelpdeskTicketFormComponent } from '@shared/components/helpdesk-ticket-form/helpdesk-ticket-form.component';
import { HelpdeskTicketOverviewComponent } from './components/helpdesk-ticket-overview/helpdesk-ticket-overview.component';
import { TimelineComponent } from './components/timeline/timeline.component';
import { AccordionModule } from '@node_modules/primeng/components/accordion/accordion';
import { UploaderComponent } from '@shared/components/uploader/uploader.component';
import { FilePreviewComponent } from '@shared/components/file-preview/file-preview.component';
import { AddUserFormComponent } from '@shared/../features/project/components/add-user-form/add-user-form.component';
import { ProfileGeneralComponent } from '@features/project/components/profile-general/profile-general.component';
import { FaqComponent } from './components/faq/faq.component';
import { CardDropdownComponent } from './components/faq/card-dropdown/card-dropdown.component';
import { CardContentComponent } from './components/faq/card-content/card-content.component';
import { ProjectCardComponent } from '@shared/components/select-project-card/project-card.component';
import { RouterModule } from '@angular/router';
import { CardQuestionsComponent } from './components/faq/card-dropdown/card-questions/card-questions.component';
import { NavComponent } from '@shared/components/nav/nav.component';

@NgModule({
  declarations: [
    DocumentListComponent,
    PdfviewerComponent,
    YourProjectManagerComponent,
    DocumentDetailRowComponent,
    HelpdeskTicketFormComponent,
    HelpdeskTicketOverviewComponent,
    TimelineComponent,
    UploaderComponent,
    FilePreviewComponent,
    AddUserFormComponent,
    ProfileGeneralComponent,
    FaqComponent,
    CardDropdownComponent,
    CardContentComponent,
    ProjectCardComponent,
    CardQuestionsComponent,
    NavComponent
  ],
  imports: [
    TranslateModule,
    CoreModule,
    CommonModule,
    EmendisCardModule,
    EmendisUiModule,
    DynamicDialogModule,
    EmendisPdfViewerModule,
    EmendisFormFieldModule,
    EmendisEditorModule,
    EmendisImageViewerModule,
    AccordionModule,
    EmendisPrimeFileUploadModule,
    RouterModule,
    HorizontalNavbarModule,
    EmendisPrimeMobileTabBarModule
  ],
  exports: [
    TranslateModule,
    CoreModule,
    DocumentListComponent,
    YourProjectManagerComponent,
    DocumentDetailRowComponent,
    HelpdeskTicketFormComponent,
    HelpdeskTicketOverviewComponent,
    AccordionModule,
    UploaderComponent,
    FilePreviewComponent,
    AddUserFormComponent,
    ProfileGeneralComponent,
    FaqComponent,
    CardDropdownComponent,
    ProjectCardComponent,
    NavComponent
  ],
  entryComponents: [
    PdfviewerComponent,
    HelpdeskTicketFormComponent,
    HelpdeskTicketOverviewComponent,
    AddUserFormComponent
  ],
  providers: [
    DialogService
  ]
})
export class SharedModule {
}
