import { Component } from '@angular/core';

@Component({
  selector: 'app-basic-layout',
  templateUrl: './basic-layout.component.html',
  styleUrls: ['./basic-layout.component.scss']
})
export class BasicLayoutComponent {
  public isNotificationBarOpen: boolean;
  public projectId: string;

  public toggleNotificationBar(): void {
    this.isNotificationBarOpen = !this.isNotificationBarOpen;
  }

  public changeProject(projectId: string): void {
    this.projectId = projectId;
  }
}
