import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { SelectItem } from 'primeng/api';
import { DialogService } from '@node_modules/primeng/components/dynamicdialog/dialogservice';
import { ProfileService } from '@shared/services/profile.service';
import { LocaleService } from '@emendis/complete';

@Component({
  selector: 'app-add-user-form',
  templateUrl: './add-user-form.component.html',
  styleUrls: ['./add-user-form.component.scss']
})
export class AddUserFormComponent implements OnInit {
  // TODO: Data should be fetched from back-end in the future
  public userTypes: { id: number; type: string; description: string }[] = [
    {
      id: 0,
      type: 'Beheerder',
      description:
        'Set ut perspiciatis unde omnis iste natus error sit volup tatem accusantium doloremque laudantium'
    },
    {
      id: 1,
      type: 'Redacteur',
      description:
        'Nemo enim ipsam voluptatem quia voluptas sit aspenatur aut odit aut fugit'
    },
    {
      id: 2,
      type: 'Analist',
      description:
        'Ne mopersi ciatis enim ipsam voluptatem quia voluptas sit aspernatur aut tatem accusantium doloremque'
    }
  ];


  public selectLanguage: any = [];

  public form = this.formBuilder.group({
    fullName: [null, Validators.required],
    email: [null, Validators.required],
    selectLanguage: [2, Validators.required],
    userTypes: [null, Validators.required]
  });

  constructor(private formBuilder: FormBuilder,
              private profileService: ProfileService,
              private localeService: LocaleService,
              public dialogService: DialogService) {
  }

  public ngOnInit(): void {
    this.getLanguages();
  }

  public getLanguages(): void {
    this.localeService.list().subscribe(event => {
      this.displayLanguages(event);
    });
  }

  public displayLanguages(event: any): any {
    this.selectLanguage = [];
    // TODO get data from back-end
    const flagArray = ['./assets/flags/nl.svg', './assets/flags/en.svg'];
    for (let i = 0; i < event.data.length; i++) {
      this.selectLanguage.push({
        value: event.data[i].id,
        label: event.data[i].name,
        url: flagArray[i]
      });
    }
  }

  // TODO: Send data to BE
  public onSubmit(): void {
    this.profileService.getNewUserData(this.form.value);
    this.dialogService.dialogComponentRef.instance.close();
  }

  public closeForm(): void {
    // To get smooth animation added dialogService. Original:  this.ref.close();
    this.dialogService.dialogComponentRef.instance.close();
  }
}
