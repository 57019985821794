import { Injectable } from '@angular/core';
import { AllProjects } from '@features/project-manager/components/projects-table/all-projects.model';
import { ApiIndexResult, ApiService, ListOptions } from '@emendis/api';
import { Observable } from '@node_modules/rxjs';
import * as faker from 'faker';
import { SprintType } from '@core/enums/sprint-type.enum';
import { Status } from '@core/enums/generic/status.enum';

@Injectable({
  providedIn: 'root'
})
// TODO when data is available delete this file and fetch data from project.service
export class AllProjectsService extends ApiService<AllProjects> {
  public projectType = SprintType;
  public status = Status;
  public currentType: string;
  public currentStatus: string;
  protected endpoint = 'unknown';

  public index<T = AllProjects>(options?: ListOptions): Observable<ApiIndexResult<T>> {
    const mainData = this.generateData();

    return Observable.create(observer => {
      observer.next({ data: mainData });
      observer.complete();
    });
  }

  public generateData(): any {
    const mainData = [];

    for (let i = 0; i < 10; i++) {
      mainData.push({
        project_id: faker.random.uuid(),
        customer: faker.name.findName(),
        project: faker.name.title(),
        project_type: this.generateTypes(),
        project_status: this.generateStatus()
      });
    }

    return mainData;
  }

  public generateTypes(): any {
    // @ts-ignore
    const keys = Object.values(this.projectType);
    this.currentType = keys[Math.floor(Math.random() * keys.length)];
    return this.currentType;
  }

  public generateStatus(): any {
    // @ts-ignore
    const keys = Object.values(this.status);
    this.currentStatus = keys[Math.floor(Math.random() * keys.length)];
    return this.currentStatus;
  }
}
