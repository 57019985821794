import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { NotificationItem } from '@emendis/ui';
import { NotificationsService } from '@shared/services/notifications.service';
import { TranslateService } from '@ngx-translate/core';
import { NotificationIconTypes } from '@core/enums/notification-icon-types.enum';
import { Subject } from 'rxjs';
import { takeUntil } from '@node_modules/rxjs/internal/operators';

@Component({
  selector: 'app-notification-bar',
  templateUrl: './notification-bar.component.html',
  styleUrls: ['./notification-bar.component.scss']
})
export class NotificationBarComponent implements OnDestroy {
  @Input() public isOpen: boolean;
  @Output() public closeNotificationBar: EventEmitter<void> = new EventEmitter();
  public dayTranslations: any;
  public notificationItems: NotificationItem[] = [];
  public isNotificationBarOpen: boolean;
  public browserLocale: string;
  public dateFormat: string;
  public headerIcon: string;
  private destroy$: Subject<boolean> = new Subject<boolean>();
  private notificationIconTypes: NotificationIconTypes = new NotificationIconTypes();

  constructor(
    private notificationsService: NotificationsService,
    private translateService: TranslateService) {
    this.dateFormat = 'D MMMM';
    this.headerIcon = 'fas fa-trash';
    this.isNotificationBarOpen = true;
    this.browserLocale = navigator.language;
    this.dayTranslations = {
      today: translateService.instant('notification-bar.date.today'),
      yesterday: translateService.instant('notification-bar.date.yesterday')
    };

    /**
     * @todo Call this function when BE is ready
     */
    // this.notificationsService.index()
    //   .pipe(takeUntil(this.destroy$))
    //   .subscribe((notificationItems) => {
    //     this.notificationItems = notificationItems.data.map(item => {
    //       item['icon'] = this.notificationIconTypes[item.type].icon;
    //       item['iconColor'] = this.notificationIconTypes[item.type].iconColor;
    //       return item;
    //     });
    //   });
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
  }
}
