import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ProjectService } from '@shared/services/project.service';
import { ProjectDataFormComponent } from '@features/project-manager/components/new-project/project-data-form/project-data-form.component';
import { CompanyAddressFormComponent } from '@features/project-manager/components/new-project/company-address-form/company-address-form.component';
import { BillingAddressFormComponent } from '@features/project-manager/components/new-project/billing-address-form/billing-address-form.component';
import { Subject } from '@node_modules/rxjs';
import { takeUntil } from '@node_modules/rxjs/internal/operators';

@Component({
  selector: 'app-new-project',
  templateUrl: './new-project.component.html',
  styleUrls: ['./new-project.component.scss']
})
export class NewProjectComponent implements OnInit, OnDestroy {
  @ViewChild(ProjectDataFormComponent)
  public projectDataFormComponent: ProjectDataFormComponent;

  @ViewChild(CompanyAddressFormComponent)
  public companyAddressFormComponent: CompanyAddressFormComponent;

  @ViewChild(BillingAddressFormComponent)
  public billingAddressFormComponent: BillingAddressFormComponent;
  public isFormDisabled: boolean = true;
  public projectFormValidity: boolean = false;
  public addressFormValidity: boolean = false;
  public billingFormDisabled: boolean = false;
  public billingFormValidity: boolean = false;
  private destroy = new Subject<void>();

  constructor(private projectService: ProjectService) {
  }

  public ngOnInit(): void {
    setTimeout(() => {
      this.disableSubmit();
    }, 500);
  }

  public disableSubmit(): void {
    this.projectDataFormComponent.form.statusChanges.pipe(takeUntil(this.destroy))
      .subscribe(result => this.checkProjectFormValidity(result));

    this.companyAddressFormComponent.form.statusChanges.pipe(takeUntil(this.destroy))
      .subscribe(result => this.checkAddressFormValidity(result));

    this.billingAddressFormComponent.form.statusChanges.pipe(takeUntil(this.destroy))
      .subscribe(result => this.checkBillingFormValidity(result));

    this.projectService.submitSameAsCompany.pipe(takeUntil(this.destroy))
      .subscribe((value) => {
        this.hideBilling(value);
      });

  }

  public checkProjectFormValidity(validity: string): void {
    this.projectFormValidity = validity === 'VALID';
    this.checkAllFormsValidity();
  }

  public checkAddressFormValidity(validity: string): void {
    this.addressFormValidity = validity === 'VALID';
    this.checkAllFormsValidity();
  }

  public checkBillingFormValidity(validity: string): void {
    this.billingFormValidity = validity === 'VALID';
    this.checkAllFormsValidity();
  }

  public hideBilling(value: boolean): void {
    this.billingFormDisabled = value === true;
    this.checkAllFormsValidity();
  }

  public checkAllFormsValidity(): void {
    if (this.billingFormDisabled) {
      this.isFormDisabled = !(this.projectFormValidity && this.addressFormValidity);
    } else {
      this.isFormDisabled = !(this.projectFormValidity && this.addressFormValidity && this.billingFormValidity);
    }
  }

  public onSubmit(formData: any): void {
    this.projectService.submitNewProject.emit(formData);
    // TODO RESET and REDIRECT.
    this.resetAllForms();
  }

  public resetAllForms(): void {
    this.projectService.resetAllForms.emit();
  }

  public getProjectData(event: any): void {
    this.projectService.getNewProjectData(event.value);
  }

  public getCompanyData(event: any): void {
    this.projectService.getNewCompanyData(event.value);
  }

  public getBillingData(event: any): void {
    this.projectService.getNewBillingData(event.value);
  }

  public ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }
}
