import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ManagerRootComponent } from './manager-root/manager-root.component';
import { EmendisRoutes } from '@core/enums/generic/routes.enum';
import { NewProjectComponent } from '@features/project-manager/pages/new-project/new-project.component';
import { ManualsManagementComponent } from './pages/manuals-management/manuals-management.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { ProjectComponent } from './pages/project/project.component';
import { DocumentsComponent } from './pages/documents/documents.component';
import { HelpdeskComponent } from './pages/helpdesk/helpdesk.component';
import { ProfileComponent } from './pages/profile/profile.component';

const routes: Routes = [
  {
    path: EmendisRoutes.new_project,
    component: NewProjectComponent,
  },
  {
    path: ':id',
    component: ManagerRootComponent,
    children: [
      {
        path: EmendisRoutes.dashboard,
        component: DashboardComponent
      },
      {
        path: EmendisRoutes.info,
        component: ProjectComponent
      },
      {
        path: EmendisRoutes.documents,
        component: DocumentsComponent
      },
      {
        path: EmendisRoutes.helpdesk,
        component: HelpdeskComponent
      },
      {
        path: EmendisRoutes.profile,
        component: ProfileComponent
      },
      {
        path: EmendisRoutes.edit_manuals,
        component: ManualsManagementComponent
      },
      {
        path: '**',
        redirectTo: `${EmendisRoutes.projects}`,
      }
    ],
  },

  {
    path: '**',
    redirectTo: `${EmendisRoutes.project_manager}/${EmendisRoutes.projects}`,
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProjectManagerRoutingModule {
}
