import { Injectable } from '@angular/core';
import { ProgressCircle } from '@features/models/progress-circle.model';

@Injectable({
  providedIn: 'root'
})
export class HoursOverviewService {
  // TODO: implement backend endpoint

  public getHoursInProject(): ProgressCircle {
    return {
      title: 'project',
      ratio: {
        part: 2.033,
        total: 2.722
      }
    };
  }

  public getHoursInBundle(): ProgressCircle {
    return {
      title: 'Uren in urenbundel',
      ratio: {
        part: 24,
        total: 120
      }
    };
  }
}
