import { Component, Input, OnInit } from '@angular/core';
import { DocumentService } from '@shared/services/document.service';
import { Document } from '@core/models/document.model';
import { Subscription } from 'rxjs';
import { PDFDialogService } from '@shared/services/pdfdialog.service';
import { ActivatedRoute } from '@angular/router';
import { FileService } from '@shared/services/file.service';
import { ProjectService } from '@shared/services/project.service';
import { Project } from '@core/models/project.model';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss']
})
export class DocumentsComponent implements OnInit {
  @Input()
  public projectId: string;
  public documents: Document[];
  public favoriteDocuments: Document[] = [];
  private routePath: Subscription;

  constructor(
    private documentService: DocumentService,
    private projectService: ProjectService,
    private pdfDialogService: PDFDialogService,
    private route: ActivatedRoute,
    private fileService: FileService) {
  }

  public ngOnInit(): void {
    this.routePath = this.route.parent.params.subscribe(event => {
      this.projectId = event.id;
      this.projectService.getDocuments(this.projectId)
        .subscribe((project: Project) => {
          this.documents = project.documents;
          this.favoriteDocuments = this.filterFavorites();
        });
    });
  }

  public onOpenDocument(onOpenDocument: Document): void {
    this.documents = this.documents.map((document) => {
      if (document.id === onOpenDocument.id && document.is_read === false) {
        this.documentService.changeDocumentRead(document.id).subscribe((response) => {
          document.is_read = true;
        });
      }
      return document;
    });
    this.fileService.fetchFile(onOpenDocument.file.id).subscribe((response) => {
      this.pdfDialogService.open(
        {
          filename: onOpenDocument.file.filename,
          src: { data: atob(response.data.file) },
          fileId: onOpenDocument.file.id
        }
      );
    });
  }

  public onFavorite(event: Document): void {
    this.documents = this.documents.map((document) => {
      if (document.id === event.id) {
        if (!document.is_favorite) {
          this.documentService.favorite(document.id).subscribe((response) => {
            document.is_favorite = !document.is_favorite;
            this.favoriteDocuments = this.filterFavorites();
          });
        } else {
          this.documentService.unFavorite(document.id).subscribe((response) => {
            document.is_favorite = !document.is_favorite;
            this.favoriteDocuments = this.filterFavorites();
          });
        }
      }
      return document;
    });
  }

  public filterFavorites(): Document[] {
    return !this.documents ? [] : this.documents.filter(document => document.is_favorite);
  }

}
