import { Component, OnDestroy, OnInit } from '@angular/core';
import { FaqService } from '@shared/services/helpdesk/faq.service';
import { Subscription } from '@node_modules/rxjs';
import { Faq } from '@core/models/helpdesk/faq.model';

@Component({
  selector: 'app-helpdesk',
  templateUrl: './helpdesk.component.html'
})
export class HelpdeskComponent implements OnInit, OnDestroy {
  public selection: [{ value: string, label: string }] = [{ value: 'show-all', label: 'faq.show-all' }];
  public faqs: Faq[];
  private subscription: Subscription;

  constructor(
    private faqService: FaqService
  ) {
  }

  public ngOnInit(): void {
    this.subscription = this.faqService.fetchFaq().subscribe(response => {
      this.faqs = response.data;
      this.displaySelection();
    });
  }

  public displaySelection(): void {
    const uniqueValues = this.removeDuplicates();

    for (let i = 0; i < uniqueValues.length; i++) {
      this.selection.push({
        value: uniqueValues[i].faq_category_base_data_value_id, label: uniqueValues[i].faq_category_base_data_value_id
      });
    }
  }

  public removeDuplicates(): { id: string, question: string, answer: string, faq_category_base_data_value_id: string }[] {
    const uniqueValues = [];
    this.faqs.forEach((item) => {

      if (uniqueValues.findIndex(i => i.faq_category_base_data_value_id === item.faq_category_base_data_value_id) === -1) {
        uniqueValues.push(item);
      }
    });
    return uniqueValues;
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
