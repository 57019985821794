import { EventEmitter, Injectable } from '@angular/core';
import { ApiService } from '@emendis/api';
import { Project } from '@core/models/project.model';
import { Observable } from '@node_modules/rxjs';
import { Endpoints } from '@core/enums/generic/endpoints.enum';

@Injectable({
  providedIn: 'root'
})
export class ProjectService extends ApiService<Project> {
  public submitNewProject = new EventEmitter<any>();
  public submitSameAsCompany = new EventEmitter<boolean>();
  public resetAllForms = new EventEmitter<any>();
  // TODO delete fake data when create new project BE ready.
  public emendisPMOptions = [
    {
      label: 'Jelte',
      value: 'jelte',
      url: './assets/flags/nl.svg'
    },
    {
      label: 'Jasper',
      value: 'jasper',
      url: './assets/flags/es.svg'
    },
    {
      label: 'Joep',
      value: 'joep',
      url: './assets/flags/fr.svg'
    }
  ];
  // TODO delete fake data when create new project BE ready.
  public billingAddressCountryOptions = [
    {
      label: 'Netherlands',
      value: 'netherlands',
      url: './assets/flags/nl.svg'
    },
    {
      label: 'Spain',
      value: 'spain',
      url: './assets/flags/es.svg'
    },
    {
      label: 'France',
      value: 'france',
      url: './assets/flags/fr.svg'
    }
  ];
  protected endpoint = Endpoints.project;

  public getProjectById(projectId: string): Observable<Project> {
    return this.get(projectId);
  }

  public getDocuments(projectId: string): Observable<Project> {
    return this.get(projectId, {
      include: ['documents']
    });
  }

  public getUnreadDocuments(projectId: string): Observable<number> {
    return this.get(projectId, {
      include: ['documents'],
      filters: [{
        field: 'read',
        value: 1
      }]
    });
  }

  public getProjectCardDetails(projectId: string): Observable<Project> {
    return this.get(projectId, {
      include: [
        'type',
        'progression',
        'method',
        'manager',
        'summary',
        'sprint',
        'latestSprint',
        'sprints.sprint-hour'
      ]
    });
  }

  public getDashboardCardDetails(projectId: string): Observable<Project> {
    return this.get(projectId, {
      include: [
        'type',
        'progression',
        'method',
        'manager',
        'summary'
      ]
    });
  }

  // TODO Send this data to BE.
  public getNewProjectData(data: {}): void {
  }

  // TODO Send this data to BE.
  public getNewCompanyData(data: {}): void {
  }

  // TODO Send this data to BE.
  public getNewBillingData(data: {}): void {
  }

}
