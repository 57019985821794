import { Component, EventEmitter, OnDestroy, OnInit, Output, } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ProjectService } from '@shared/services/project.service';
import { takeUntil } from '@node_modules/rxjs/internal/operators';
import { Subject } from '@node_modules/rxjs';

@Component({
  selector: 'app-project-data-form',
  templateUrl: './project-data-form.component.html',
})
export class ProjectDataFormComponent implements OnInit, OnDestroy {
  @Output()
  public onProjectDataSubmit: EventEmitter<any> = new EventEmitter();
  public form;
  public options = this.projectService.emendisPMOptions;
  private destroy = new Subject<void>();

  constructor(
    private formBuilder: FormBuilder,
    private projectService: ProjectService
  ) {
  }

  public ngOnInit(): void {
    this.buildForms();
    this.projectService.submitNewProject.subscribe(() => {
      this.submitForm();
    });

    this.projectService.resetAllForms.pipe(takeUntil(this.destroy))
      .subscribe((() => {
        this.resetForm();
      }));
  }

  public buildForms(): void {
    this.form = this.formBuilder.group({
      contact_person: ['', [Validators.required]],
      contact_person_telephone: ['', [Validators.required]],
      contact_person_email: ['', [Validators.required, Validators.email]],
      company_name: ['', [Validators.required]],
      company_phone: ['', [Validators.required]],
      company_email: ['', [Validators.email]],
      emendis_pm: ['Jasper', [Validators.required]]
    });
  }

  public submitForm(): void {
    this.onProjectDataSubmit.emit(this.form);
  }

  public resetForm(): void {
    this.form.reset();
  }

  public ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }
}
