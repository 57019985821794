import { Component, HostListener, Input, OnInit } from '@angular/core';
import { Project } from '@core/models/project.model';
import { IntroductionCardModel } from '@core/models/Introduction-card.model';

@Component({
  selector: 'app-your-projectmanager',
  templateUrl: './your-projectmanager.component.html',
  styleUrls: ['./your-projectmanager.component.scss']
})
export class YourProjectManagerComponent implements OnInit {
  @Input()
  public project?: Project;
  @Input()
  public informationCard?: IntroductionCardModel;
  public innerWidth: number;

  public ngOnInit(): void {
    this.innerWidth = window.innerWidth;
  }

  @HostListener('window:resize', ['$event'])
  public onResize(event: Event): void {
    this.innerWidth = window.innerWidth;
  }
}
