import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Project } from '@core/models/project.model';
import { ProjectDetailCard } from '@core/models/project-detail-card.model';
import { ProjectService } from '@shared/services/project.service';
import { ActivatedRoute } from '@angular/router';
import { IntroductionCardModel } from '@core/models/Introduction-card.model';
import { Subscription } from '@node_modules/rxjs';
import { SprintStates } from '@emendis/ui';
import { SprintOverviewDetail } from '@core/models/sprint-overview-details.model';

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html'
})
export class ProjectComponent implements OnInit, OnDestroy {
  @Input()
  public projectId: string;
  public project: Project;
  public projectDetailCards: ProjectDetailCard[];
  public latestSprintProgressbarValues: object[];
  public progressProjectProgressbarValues: object[];
  public sprintOverviewDetails: SprintOverviewDetail[];
  public summarySprintProjectDetailCards: ProjectDetailCard[];
  private projectInformationCard: IntroductionCardModel;
  private yourProjectManagerCard: IntroductionCardModel;
  private projectDetails: Subscription = new Subscription();
  private routePath: Subscription = new Subscription();

  constructor(
    private projectService: ProjectService,
    private route: ActivatedRoute
  ) {
  }

  public ngOnInit(): void {
    this.routePath = this.route.parent.params.subscribe(event => {
      this.projectId = event.id;
      this.makeCardDetailRequests();
    });
    this.sprintOverviewDetails = [
      {
        header: 'Sprint 3',
        headerStatus: SprintStates.Planned,
        bulletPoints: ['Sprint 3', 'Sprint 3', 'Sprint 3', 'Sprint 3'],
        src: '/assets/pdf/lorem-ipsum.pdf',
        filename: 'Sprint 3 rapport',
        headerIcon: 'far fa-clock',
        id: '3'
      },
      {
        header: 'Sprint 2',
        headerStatus: SprintStates.Active,
        bulletPoints: ['Sprint 2', 'Sprint 2', 'Sprint 2', 'Sprint 2'],
        src: '/assets/pdf/lorem-ipsum.pdf',
        filename: 'Sprint 2 rapport',
        headerIcon: 'fas fa-spinner',
        id: '2'
      },
      {
        header: 'Sprint 1',
        headerStatus: SprintStates.Finished,
        bulletPoints: ['Sprint 1', 'Sprint 1', 'Sprint 1', 'Sprint 1'],
        src: undefined,
        filename: 'Sprint 1 rapport',
        headerIcon: 'fas fa-check',
        id: '1'
      }
    ];
  }

  public makeCardDetailRequests(): void {
    this.projectDetails = this.projectService.getProjectCardDetails(this.projectId).subscribe((projectResponse) => {
      this.project = { ...this.project, ...projectResponse };
      this.setProjectDetailCards();
    });
  }

  public ngOnDestroy(): void {
    this.routePath.unsubscribe();
    this.projectDetails.unsubscribe();
  }

  public setProjectDetailCards(): void {
    this.projectInformationCard = {
      title: 'headers.project-information',
      imageHeader: false,
      buttons: [
        { label: 'buttons.functional-design', path: '' }
      ],
      imageUrl: '../assets/images/emendis-complete-logo.png',
      rows: [
        { label: 'project.number', data: this.project.number },
        { label: 'project.type', data: this.project.type.value },
        { label: 'project.method', data: this.project.method.value }
      ]
    };
    this.yourProjectManagerCard = {
      title: 'headers.yourprojectmanager',
      imageHeader: true,
      buttons: [
        { label: 'buttons.service-agreement', path: '' }
      ],
      imageUrl: '../assets/images/man-face.png',
      rows: [
        { label: 'project.manager', data: this.project.manager.name },
        { label: 'project.phone-number', data: this.project.phoneNumber },
        { label: 'project.email', data: this.project.manager.email || '' }
      ]
    };
    this.summarySprintProjectDetailCards = [
      {
        icon: 'far fa-business-time',
        text: 'total-spent-hours',
        data: this.project.progression.hours_spent
      },
      {
        icon: 'far fa-users',
        text: 'total-employees',
        data: this.project.latestSprint.developer_count
      },
      {
        icon: 'far fa-award',
        text: 'storypoints-processed',
        data: this.project.latestSprint.story_points_processed
      },
      {
        icon: 'far fa-ticket-alt',
        text: 'tickets-treated',
        data: this.project.latestSprint.tickets_selected
      },
      {
        icon: 'far fa-award',
        text: 'tickets-finished',
        data: this.project.latestSprint.tickets_finished
      }
    ];
    this.latestSprintProgressbarValues = [
      { value: this.project.backendCurrentSprint, label: 'backend' },
      { value: this.project.frontendCurrentSprint, label: 'frontend' }
    ];
    this.progressProjectProgressbarValues = [{
      value: this.project.progression.percentage_completed,
      label: ''
    }];
    this.projectDetailCards = [
      {
        icon: 'far fa-business-time',
        text: 'total-spent-hours',
        data: this.project.progression.hours_spent
      },
      {
        icon: 'far fa-clock',
        text: 'remaining-hours',
        data: (this.project.progression.hours_available - this.project.progression.hours_spent)
      },
      {
        icon: 'far fa-hashtag',
        text: 'sprints-finished',
        data: this.project.sprints_finished
      }];
  }
}
