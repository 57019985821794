import { Component, Input, OnDestroy } from '@angular/core';
import { NavBarItem } from '@emendis/ui';
import { BootstrapBreakpoints } from '@core/enums/bootstrap-breakpoints.enum';
import { takeUntil } from '@node_modules/rxjs/internal/operators';
import { Subject } from '@node_modules/rxjs';
import { BreakpointObserver } from '@angular/cdk/layout';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnDestroy {
  @Input()
  public navBarItems: NavBarItem[] = [];
  public isMobile: boolean;
  private destroy = new Subject<void>();

  constructor(breakpointObserver: BreakpointObserver) {
    breakpointObserver
      .observe(BootstrapBreakpoints.smDown)
      .pipe(takeUntil(this.destroy))
      .subscribe(({ matches }) => this.isMobile = matches);
  }

  public ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }
}
