import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { isArray } from 'util';

@Component({
  selector: 'app-uploader',
  templateUrl: './uploader.component.html',
  styleUrls: ['./uploader.component.scss']
})
export class UploaderComponent {
  @Input() public id: string;
  @Input() public label: string;
  @Input() public multiple: boolean = true;
  @Input() public control: AbstractControl = new FormControl();
  @Input() public previewClass: string = 'col-3';
  @Input() public extensionsAccepted: string[] = [];
  @Output() public cardClick: EventEmitter<string> = new EventEmitter();

  public get files(): File[] {
    return this.control.value || [];
  }

  public getControlName(): string | null {
    const formGroup = this.control.parent.controls;
    const controlName = Object.keys(formGroup).find(name => this.control === formGroup[name]) || null;

    return this.id ? this.id + '-' + controlName : controlName;
  }

  public setFiles(files: File[]): void {
    if (isArray(files) && files.length > 0) {
      this.setFile(files);
    }
  }

  public removeFile(filename: string): void {
    this.deleteFile(filename);
  }

  public get fileExtensions(): string {
    return isArray(this.extensionsAccepted) ? this.extensionsAccepted.join(',') : '';
  }

  private setFile(files: File[]): void {
    this.control.setValue(this.multiple ? [...this.files, ...files] : files);
  }

  private deleteFile(filename: string): void {
    const index = this.files.findIndex(file => file.name === filename);

    this.control.setValue(this.files.filter((item, i) => i !== index));
  }
}
