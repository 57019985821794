import { Component, Input } from '@angular/core';
import { Faq } from '@core/models/helpdesk/faq.model';

@Component({
  selector: 'app-card-dropdown',
  templateUrl: './card-dropdown.component.html',
  styleUrls: ['./card-dropdown.component.scss']
})
export class CardDropdownComponent {
  @Input()
  public faqData: Faq[];
  @Input()
  public selectedFilter: string;
  public keys = Object.keys;
}

