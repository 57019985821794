import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Document } from '@core/models/document.model';

@Component({
  selector: 'app-favorite-documents-card',
  templateUrl: './favorite-documents-card.component.html'
})
export class FavoriteDocumentsCardComponent  {
  @Input()
  public favoriteDocuments: Document[];
  @Output()
  public onFavorite: EventEmitter<Document> = new EventEmitter<Document>();
  @Output()
  public onOpenDocument: EventEmitter<Document> = new EventEmitter<Document>();
  public title = 'headers.favorites';
}
