import { TranslateLoader } from '@ngx-translate/core';
import { Observable } from '@node_modules/rxjs';
import { TranslationIndexedDbModel } from '@emendis/complete';

export class TranslationLoader implements TranslateLoader {
  public getTranslation(lang: string): Observable<any> {
    return new Observable((resolver) => {
      TranslationIndexedDbModel.query().get(lang).then((result) => {
        resolver.next(result.value);
      }, err => {
        resolver.next({});
      }).catch(() => {
        resolver.next({});
      });
    });
  }
}
