import { Component, Input } from '@angular/core';
import { TableColumn } from '@emendis/ui';
import { LazyLoadEvent } from '@node_modules/primeng/components/common/lazyloadevent';
import { AllProjects } from '@features/project-manager/components/projects-table/all-projects.model';

@Component({
  selector: 'app-projects-table',
  templateUrl: './projects-table.component.html',
  styleUrls: ['./projects-table.component.scss']
})
export class ProjectsTableComponent {
  @Input() public tableColumns: TableColumn[];
  @Input() public paginator: any = {};
  @Input() public tableRows: AllProjects[] = [];
  @Input() public allRows: AllProjects[];

  public loadTableData(event: LazyLoadEvent): void {
    setTimeout(() => {
      this.tableRows = this.allRows.slice(event.first, event.first + event.rows);
    }, 2000);
  }
}
