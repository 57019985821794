import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { SelectItem } from '@node_modules/primeng/components/common/selectitem';

@Component({
  selector: 'app-form-dropdown',
  templateUrl: './form-dropdown.component.html',
})
export class FormDropdownComponent {
  @Input()
  public inputTitle: string;
  @Input()
  public control: AbstractControl;
  @Input()
  public options: SelectItem[];
}
