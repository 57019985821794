import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ProjectService } from '@shared/services/project.service';
import { takeUntil } from '@node_modules/rxjs/internal/operators';
import { Subject } from '@node_modules/rxjs';

@Component({
  selector: 'app-company-address-form',
  templateUrl: './company-address-form.component.html',
})
export class CompanyAddressFormComponent implements OnInit, OnDestroy {
  @Output()
  public onCompanyDataSubmit: EventEmitter<any> = new EventEmitter();
  public form;
  public menuVisible: boolean = true;
  public options = this.projectService.billingAddressCountryOptions;
  private destroy = new Subject<void>();

  constructor(
    private formBuilder: FormBuilder,
    private projectService: ProjectService
  ) {
  }

  public ngOnInit(): void {
    this.buildForm();
    this.projectService.submitNewProject.pipe(takeUntil(this.destroy))
      .subscribe(() => {
        this.submitForm();
      });

    this.projectService.resetAllForms.pipe(takeUntil(this.destroy))
      .subscribe((() => {
        this.resetForm();
      }));
  }

  public buildForm(): void {
    this.form = this.formBuilder.group({
      zip_code: ['', [Validators.required]],
      house_number: ['', [Validators.required]],
      street: ['', [Validators.required]],
      city: ['', [Validators.required]],
      country: ['France']
    });
  }

  public submitForm(): void {
    this.onCompanyDataSubmit.emit(this.form);
  }

  public hideInputs(event: boolean): void {
    this.projectService.submitSameAsCompany.emit(event);
  }

  public resetForm(): void {
    this.form.reset();
  }

  public ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }
}
