import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { InvoiceService } from '@shared/services/invoice.service';
import { Invoice } from '@core/models/invoice.model';
import { PDFDialogService } from '@shared/services/pdfdialog.service';
import { ApiIndexResult } from '@emendis/api';
import { TranslateService } from '@node_modules/@ngx-translate/core';
import { InvoiceStatuses } from '@core/enums/invoice-statuses.enum';
import { FileService } from '@shared/services/file.service';

@Component({
  selector: 'app-invoices-widget',
  templateUrl: './invoices-widget.component.html',
  styleUrls: ['./invoices-widget.component.scss']
})
export class InvoicesWidgetComponent implements OnInit {
  public invoices: Invoice[] = [];
  @Output() public showAllInvoices: EventEmitter<void> = new EventEmitter();
  @Output() public addInvoice: EventEmitter<void> = new EventEmitter();

  constructor(
    private invoiceService: InvoiceService,
    private fileService: FileService,
    private translateService: TranslateService,
    private pdfDialogService: PDFDialogService) {
  }

  public ngOnInit(): void {
    this.invoiceService
      .index({
        include: ['status'],
        perPage: 3,
        sort: [{
          field: 'created_at',
          direction: 'desc'
        }]
      })
      .subscribe((response: ApiIndexResult<Invoice>) => {
        this.invoices = response.data;
      });
  }

  public getStatus(status: InvoiceStatuses): string {
    return {
      paid: this.translateService.instant('invoice.paid'),
      outstanding: this.translateService.instant('invoice.outstanding'),
      reminded: this.translateService.instant('invoice.reminded'),
      admonished: this.translateService.instant('invoice.admonished'),
    }[status];
  }

  public getIcon(status: InvoiceStatuses): string {
    return {
      paid: 'far fa-check',
      outstanding: 'far fa-spinner',
      reminded: 'far fa-calendar-alt',
      admonished: 'fas fa-exclamation-triangle',
    }[status];
  }

  public onOpenPDF(invoice: Invoice): void {
    event.preventDefault();

    this.fileService
      .download(invoice.file.id)
      .subscribe(blob => {
        const reader = new FileReader();

        reader.readAsArrayBuffer(blob);

        reader.onloadend = () => {
          this.pdfDialogService.open({
            fileId: invoice.file.id,
            filename: invoice.file.filename,
            src: { data: reader.result },
          });
        };
      });
  }
}
