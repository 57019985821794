import { Injectable } from '@angular/core';
import { ApiIndexResult, ApiService, ListOptions } from '@emendis/api';
import { Helpdeskticket } from '@shared/models/helpdeskticket.model';
import * as faker from 'faker';
import { Observable } from '@node_modules/rxjs';
import { Endpoints } from '@core/enums/generic/endpoints.enum';

@Injectable({
  providedIn: 'root'
})
export class TicketService extends ApiService<Helpdeskticket> {
  // Displays tickets in helpdesk page your tickets.
  protected endpoint = Endpoints.ticket;

  // TODO: Remove when BE is ready
  public index<T = Helpdeskticket>(options?: ListOptions): Observable<ApiIndexResult<T>> {
    const TimelineItem = [];
    const Attachment = [];
    const dummyData = [];

    for (let i = 0; i < 3; i++) {
      Attachment.push({
        id: faker.random.uuid(),
        path: faker.image.image(),
        filename: faker.name.findName(),
        created_at: faker.date.past(),
        updated_at: faker.date.recent()
      });
    }

    for (let i = 0; i < 3; i++) {
      TimelineItem.push({
        id: faker.random.uuid(),
        author: faker.name.findName(),
        content: faker.lorem.paragraph(),
        path: faker.image.avatar(),
        created_at: faker.date.past(),
      });
    }

    for (let i = 0; i < 10; i++) {
      dummyData.push({
        id: faker.random.uuid(),
        type: faker.lorem.word(),
        subject: faker.lorem.sentence(),
        description: faker.lorem.paragraph(),
        author: faker.name.findName(),
        browser: faker.internet.userAgent(),
        timeline_items: TimelineItem,
        attachments: Attachment,
        processed: faker.random.boolean(),
        created_at: faker.date.past(),
        updated_at: faker.date.recent()
      });
    }

    return Observable.create(observer => {
      observer.next({ data: dummyData });
      observer.complete();
    });
  }
}
