import { MissingTranslationHandler, MissingTranslationHandlerParams} from '@ngx-translate/core';

export class CustomMissingTranslationHandler implements MissingTranslationHandler {
  public handle(params: MissingTranslationHandlerParams): string {
    if (!params.key.includes('myemendis.') && params.translateService) {
      return params.translateService.instant(`myemendis.${params.key}`);
    }

    return `[[ ${params.key} ]]`;
  }
}
