import { AfterViewInit, ChangeDetectorRef, Component, Input, QueryList, TemplateRef, ViewChildren } from '@angular/core';
import { SprintOverviewDetail } from '@core/models/sprint-overview-details.model';
import { PDFDialogService } from '@shared/services/pdfdialog.service';

@Component({
  selector: 'app-sprint-overview-card',
  templateUrl: './sprint-overview-card.component.html',
  styleUrls: ['./sprint-overview-card.component.scss']
})
export class SprintOverviewCardComponent implements AfterViewInit {
  @Input()
  public title: string;
  @Input()
  public sprintDetails: SprintOverviewDetail[] = [];
  @ViewChildren('tabRef')
  public tabs: QueryList<TemplateRef<any>>;

  constructor(private changeDetectorRef: ChangeDetectorRef,
              private pdfDialogService: PDFDialogService) {
  }

  public ngAfterViewInit(): void {
    this.updateTemplates();
    this.tabs.changes.subscribe(() => {
      this.updateTemplates();
    });
  }

  public updateTemplates(): void {
    this.sprintDetails.forEach((tab, i) => {
      tab.contentTemplate = this.tabs.toArray()[i];
    });

    this.changeDetectorRef.detectChanges();
  }

  public openSprintRapport(details: SprintOverviewDetail): void {
    if (details.src) {
      this.pdfDialogService.open(details);
    }
  }
}
