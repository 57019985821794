import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ManualService } from '@shared/services/manual.service';
import { Manual } from '@core/models/manual.model';

@Component({
  selector: 'app-manual-form',
  templateUrl: './manual-form.component.html',
  styleUrls: ['./manual-form.component.scss']
})
export class ManualFormComponent implements OnInit {
  @Input()
  public receivedData: Manual[];

  public form: FormGroup = this.formBuilder.group({
    emendis_manual: '',
    jira_manual: '',
    myemendis_manual: '',
  });
  public files: any = [];
  public manuals: File[] = [];
  public displayUploads: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private manualService: ManualService
  ) {
  }

  public ngOnInit(): void {
    this.manualService.onFormSubmitted.subscribe(() => {
      this.submitForm();
    });
  }

  public submitForm(): void {
    // @ts-ignore
    this.manuals = Object.values(this.form.value);
    this.manualService.receiveManualDocuments(this.manuals);
  }

  // TODO replace manuals Emendis, Jira, myEmendis with BE base data value id base_data_category_id
  public setFiles(files: File[], title: string): void {
    if (title === 'Emendis') {
      this.files = [];
      this.files.push(files[0]);
      this.form.controls.emendis_manual.setValue(this.files);

    } else if (title === 'Jira') {
      this.files = [];
      this.files.push(files[0]);
      this.form.controls.jira_manual.setValue(this.files);

    } else if (title === 'myEmendis') {
      this.files = [];
      this.files.push(files[0]);
      this.form.controls.myemendis_manual.setValue(this.files);
    }
  }

  // TODO implement delete functionality when BE is available.
  public deleteFile(file: any): any {
  }
}

