import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavBarItem } from '@emendis/ui';
import { TranslateService } from '@node_modules/@ngx-translate/core';
import { ProjectService } from '@shared/services/project.service';
import { ActivatedRoute } from '@angular/router';
import { Subject, Subscription } from '@node_modules/rxjs';
import { takeUntil } from '@node_modules/rxjs/internal/operators';
import { EmendisRoutes } from '@core/enums/generic/routes.enum';

@Component({
  selector: 'app-project-root',
  templateUrl: './project-root.component.html'
})
export class ProjectRootComponent implements OnInit, OnDestroy {
  public navBarItems: NavBarItem[] = [];
  public projectId: string;
  private subscription: Subscription = new Subscription();
  private destroy = new Subject<void>();

  constructor(
    private projectService: ProjectService,
    private translateService: TranslateService,
    private route: ActivatedRoute,
  ) {
    this.subscription.add(this.route.params.pipe(takeUntil(this.destroy))
      .subscribe(event => {
        this.projectId = event.id;
        this.translateService.stream('nav.item.dashboard').pipe(takeUntil(this.destroy))
          .subscribe(() => {
            this.setNavBarItems();
          });
      }));
  }

  public ngOnInit(): void {
    this.subscription.add(
      this.projectService.getUnreadDocuments(this.projectId).pipe(takeUntil(this.destroy))
        .subscribe((response) => {
          if (!!response) {
            this.navBarItems = [...this.navBarItems];
          }
        })
    );
  }

  public setNavBarItems(): void {
    this.navBarItems = [
      {
        title: this.translateService.instant('nav.item.dashboard'),
        routerUrl: `/${EmendisRoutes.admin}/${EmendisRoutes.project}/${this.projectId}/${EmendisRoutes.dashboard}`,
        icon: 'fas fa-columns',
        exact: true
      },
      {
        title: this.translateService.instant('nav.item.projects'),
        routerUrl: `/${EmendisRoutes.admin}/${EmendisRoutes.project}/${this.projectId}/${EmendisRoutes.info}`,
        icon: 'far fa-file-chart-line',
        exact: true
      },
      {
        title: this.translateService.instant('nav.item.documents'),
        routerUrl: `/${EmendisRoutes.admin}/${EmendisRoutes.project}/${this.projectId}/${EmendisRoutes.documents}`,
        icon: 'far fa-folder',
        exact: true
      },
      {
        title: this.translateService.instant('nav.item.helpdesk'),
        routerUrl: `/${EmendisRoutes.admin}/${EmendisRoutes.project}/${this.projectId}/${EmendisRoutes.helpdesk}`,
        icon: 'far fa-question-circle',
        exact: true
      },
      {
        title: this.translateService.instant('nav.item.profile'),
        routerUrl: `/${EmendisRoutes.admin}/${EmendisRoutes.project}/${this.projectId}/${EmendisRoutes.profile}`,
        icon: 'far fa-user',
        exact: true
      }
    ];
  }

  public ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }
}
