export enum BootstrapBreakpoints {
  xs = '(max-width: 575.98px)',
  sm = '(min-width: 576px) and (max-width: 767.98px)',
  md = '(min-width: 768px) and (max-width: 991.98px)',
  lg = '(min-width: 992px) and (max-width: 1199.98px)',
  xl = '(min-width: 1200px)',
  xsUp = '(min-width: 0px)',
  smUp = '(min-width: 576px)',
  mdUp = '(min-width: 768px)',
  lgUp = '(min-width: 992px)',
  xlUp = '(min-width: 1200px)',
  xsDown = '(max-width: 575.98px)',
  smDown = '(max-width: 767.98px)',
  mdDown = '(max-width: 991.98px)',
  lgDown = '(max-width: 1199.98px)'
}
