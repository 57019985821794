import { Component, Input } from '@angular/core';
import { TimelineItem } from '@shared/models/timeline-item.model';

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss']
})
export class TimelineComponent {
  @Input() public timelineItems: TimelineItem[] = [];
}
