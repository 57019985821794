export enum EmendisRoutes {
  info = 'info',
  admin = 'admin',
  project = 'project',
  edit_manuals = 'edit-manuals',
  projects = 'projects',
  auth = 'auth',
  dashboard = 'dashboard',
  documents = 'documents',
  helpdesk = 'helpdesk',
  profile = 'profile',
  project_manager = 'project-manager',
  translation = 'translation',
  login = 'login',
  new_project = 'new-project'
}
