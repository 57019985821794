import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavBarItem } from '@emendis/ui';
import { Subject, Subscription } from '@node_modules/rxjs';
import { ProjectService } from '@shared/services/project.service';
import { TranslateService } from '@node_modules/@ngx-translate/core';
import { EmendisRoutes } from '@core/enums/generic/routes.enum';
import { takeUntil } from '@node_modules/rxjs/internal/operators';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-manager-root',
  templateUrl: './manager-root.component.html',
  styleUrls: ['./manager-root.component.scss']
})
export class ManagerRootComponent implements OnInit, OnDestroy {
  public navBarItems: NavBarItem[] = [];
  public projectId: string;
  private destroy = new Subject<void>();
  private subscription: Subscription = new Subscription();

  constructor(
    private projectService: ProjectService,
    private translateService: TranslateService,
    private route: ActivatedRoute
  ) {
    this.subscription.add(this.route.params.pipe(takeUntil(this.destroy))
      .subscribe(event => {
        this.projectId = event.id;
        this.translateService.stream('nav.item.dashboard').pipe(takeUntil(this.destroy))
          .subscribe(() => {
            this.setNavBarItems();
          });
      }));
  }

  public ngOnInit(): void {
    this.setNavBarItems();
  }

  public setNavBarItems(): void {
    this.navBarItems = [
      {
        title: this.translateService.instant('nav.item.dashboard'),
        routerUrl: `/${EmendisRoutes.project_manager}/${EmendisRoutes.project}/${this.projectId}/${EmendisRoutes.dashboard}`,
        icon: 'fas fa-columns',
        exact: true
      },
      {
        title: this.translateService.instant('nav.item.projects'),
        routerUrl: `/${EmendisRoutes.project_manager}/${EmendisRoutes.project}/${this.projectId}/${EmendisRoutes.info}`,
        icon: 'far fa-file-chart-line',
        exact: true
      },
      {
        title: this.translateService.instant('nav.item.documents'),
        routerUrl: `/${EmendisRoutes.project_manager}/${EmendisRoutes.project}/${this.projectId}/${EmendisRoutes.documents}`,
        icon: 'far fa-folder',
        exact: true
      },
      {
        title: this.translateService.instant('nav.item.helpdesk'),
        routerUrl: `/${EmendisRoutes.project_manager}/${EmendisRoutes.project}/${this.projectId}/${EmendisRoutes.helpdesk}`,
        icon: 'far fa-question-circle',
        exact: true
      },
      {
        title: this.translateService.instant('nav.item.profile'),
        routerUrl: `/${EmendisRoutes.project_manager}/${EmendisRoutes.project}/${this.projectId}/${EmendisRoutes.profile}`,
        icon: 'far fa-user',
        exact: true
      }
    ];
  }

  public ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }
}

