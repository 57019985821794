export enum Endpoints {
  faq = 'helpdesk/faq',
  helpdesk = 'helpdesk',
  ticket = 'ticket',
  document = 'document',
  file = 'file',
  invoice = 'invoice',
  manual = 'manual',
  notifications = 'notifications',
  profile = 'profile',
  project = 'project'
}
