import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmendisCardModule, EmendisPrimeButtonModule, HorizontalNavbarModule } from '@emendis/ui';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '@shared/shared.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    EmendisCardModule,
    HorizontalNavbarModule,
    HorizontalNavbarModule,
    EmendisCardModule,
    TranslateModule,
    EmendisPrimeButtonModule,
    HorizontalNavbarModule,
    SharedModule
  ]
})
export class DashboardModule {
}
