import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ProjectService } from '@shared/services/project.service';
import { Project } from '@core/models/project.model';
import { ProjectDetailCard } from '@core/models/project-detail-card.model';
import { ActivatedRoute } from '@angular/router';
import { IntroductionCardModel } from '@core/models/Introduction-card.model';
import { Subscription } from '@node_modules/rxjs';
import { ProjectRequests } from '@core/enums/project-requests.enum';
import { DocumentService } from '@shared/services/document.service';
import { Document } from '@core/models/document.model';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {
  @Input()
  public projectId: string;
  public project: Project;
  public projectDetailCards: ProjectDetailCard[];
  public summaryProjectProgressbarValues: object[];
  public documentList: Document[];
  private yourProjectManagerCard: IntroductionCardModel;
  private projectInformationCard: IntroductionCardModel;
  private subscriptions: Subscription = new Subscription();

  constructor(
    private projectService: ProjectService,
    private documentService: DocumentService,
    private route: ActivatedRoute
  ) {
    this.subscriptions = new Subscription();
  }

  public ngOnInit(): void {
    this.subscriptions.add(this.route.parent.params.subscribe(event => this.projectId = event.id));
    this.subscriptions.add(this.setCardDetails());

    this.projectService.getDocuments(this.projectId)
      .subscribe(response => this.documentList = response.documents);
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public setCardDetails(): Subscription {
    return this.projectService.getDashboardCardDetails(this.projectId).subscribe((response) => {
      this.project = { ...this.project, ...response };
      this.setProjectDetailCards();
    });
  }

  public setProjectDetailCards(): void {
    this.projectInformationCard = {
      title: 'headers.project-information',
      imageHeader: false,
      buttons: [
        { label: 'buttons.functional-design', path: '' }
      ],
      imageUrl: '../assets/images/emendis-complete-logo.png',
      rows: [
        { label: 'project.number', data: this.project.number },
        { label: 'project.type', data: this.project.type.value },
        { label: 'project.method', data: this.project.method.value }
      ]
    };

    this.projectDetailCards = [
      {
        icon: 'far fa-business-time',
        text: 'total-spent-hours',
        data: this.project.progression.hours_spent || 0
      },
      {
        icon: 'far fa-clock',
        text: 'remaining-hours',
        data: this.project.progression.hours_available || 0
      },
      {
        icon: 'far fa-hashtag',
        text: 'sprints-finished',
        data: this.project.sprints_finished || 0
      }
    ];
    this.yourProjectManagerCard = {
      title: 'headers.yourprojectmanager',
      imageHeader: true,
      buttons: [
        { label: 'buttons.service-agreement', path: '' }
      ],
      imageUrl: '../assets/images/man-face.png',
      rows: [
        { label: 'project.manager', data: this.project.manager.name },
        { label: 'project.phone-number', data: this.project.phoneNumber },
        { label: 'project.email', data: this.project.manager.email }
      ]
    };

    this.summaryProjectProgressbarValues = [
      { value: this.project.backendCurrentSprint, label: 'Backend' },
      { value: this.project.frontendCurrentSprint, label: 'Frontend' }
    ];
  }
}
