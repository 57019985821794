import { Component, Input } from '@angular/core';
import { AddUserFormComponent } from '@features/project/components/add-user-form/add-user-form.component';
import { DialogService } from '@node_modules/primeng/components/dynamicdialog/dialogservice';
import { Users } from '@core/models/profile/users.model';

@Component({
  selector: 'app-profile-add-user',
  templateUrl: './profile-add-user.component.html',
  styleUrls: ['./profile-add-user.component.scss']
})
export class ProfileAddUserComponent {
  @Input()
  public userCard: Users;

  constructor(private dialogService: DialogService) {
  }

  public displayForm(): void {
    this.dialogService.open(AddUserFormComponent, { showHeader: false });
  }
}
