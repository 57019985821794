import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-file-preview',
  templateUrl: './file-preview.component.html',
  styleUrls: ['./file-preview.component.scss']
})
export class FilePreviewComponent {
  @Input() public styleClass: string = 'col-3';
  @Input() public items: any[];
  @Input() public deletable: boolean = false;
  @Input() public nameProperty: string = 'name';
  @Input() public imagePathProperty: string;
  @Output() public removeClick: EventEmitter<string> = new EventEmitter();
  @Output() public cardClick: EventEmitter<string> = new EventEmitter();

  constructor(private domSanitizer: DomSanitizer) {
  }

  public getPath(file: File): SafeResourceUrl {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(file));
  }

  public handleRemoveIconClick(event: Event, filename: string): void {
    event.stopPropagation();
    event.stopImmediatePropagation();

    this.removeClick.next(filename);
  }

  public isImageType(file: File): boolean {
    return (file.type as string).indexOf('image') !== -1;
  }
}
