import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProjectRootComponent } from './project-root/project-root.component';
import { SharedModule } from '@shared/shared.module';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { ProjectRoutingModule } from './project-routing.module';
import { RouterModule } from '@angular/router';
import { DashboardModule } from './pages/dashboard/dashboard.module';
import {
  EmendisAccordionModule,
  EmendisCardModule,
  EmendisPrimeButtonModule,
  EmendisPrimeInputModule,
  EmendisPrimeProgressBarModule,
  EmendisProgressCircleModule,
  HorizontalNavbarModule,
  EmendisTableModule,
  EmendisPrimeMobileTabBarModule,
  EmendisPrimeInputSwitchModule,
  EmendisPrimeFileUploadModule,
  EmendisPrimeDropdownModule
} from '@emendis/ui';
import { ProjectDetailsComponent } from './components/project-details-card/project-details-card.component';
import { ProjectDetailCardComponent } from './components/project-details-card/project-detail-card/project-detail-card.component';
import { InvoicesWidgetComponent } from '@features/project/components/invoices-widget/invoices-widget.component';
import { ProjectComponent } from './pages/project/project.component';
import { DocumentsComponent } from './pages/documents/documents.component';
import { DocumentsOverviewCardComponent } from './components/documents-overview-card/documents-overview-card.component';
import { FavoriteDocumentsCardComponent } from './components/favorite-documents-card/favorite-documents-card.component';
import { HelpdeskComponent } from './pages/helpdesk/helpdesk.component';
import { HelpdeskSearchComponent } from './components/helpdesk-search/helpdesk-search.component';
import { HoursOverviewComponent } from './components/hours-overview/hours-overview.component';
import { HelpdeskTicketsWidgetComponent } from '@features/project/components/helpdesk-tickets-widget/helpdesk-tickets-widget.component';
import { SprintOverviewCardComponent } from './components/sprint-overview-card/sprint-overview-card.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ProfileSettingsComponent } from './components/profile-settings/profile-settings.component';
import { ProfileAddUserComponent } from './components/profile-add-user/profile-add-user.component';
import { DefaultManualsComponent } from './components/default-manuals/default-manuals.component';

@NgModule({
  declarations: [
    ProjectRootComponent,
    DashboardComponent,
    InvoicesWidgetComponent,
    ProjectDetailCardComponent,
    ProjectDetailsComponent,
    HoursOverviewComponent,
    ProjectComponent,
    DocumentsComponent,
    DocumentsOverviewCardComponent,
    FavoriteDocumentsCardComponent,
    DocumentsOverviewCardComponent,
    HelpdeskComponent,
    HelpdeskSearchComponent,
    ProjectComponent,
    HoursOverviewComponent,
    HelpdeskComponent,
    HelpdeskTicketsWidgetComponent,
    SprintOverviewCardComponent,
    HoursOverviewComponent,
    ProfileComponent,
    ProfileSettingsComponent,
    ProfileAddUserComponent,
    DefaultManualsComponent
  ],
  exports: [
    HoursOverviewComponent,
    ProjectDetailCardComponent,
    DefaultManualsComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    ProjectRoutingModule,
    RouterModule,
    DashboardModule,
    EmendisPrimeButtonModule,
    EmendisCardModule,
    EmendisTableModule,
    EmendisPrimeProgressBarModule,
    EmendisAccordionModule,
    EmendisPrimeProgressBarModule,
    EmendisPrimeInputModule,
    EmendisTableModule,
    EmendisAccordionModule,
    EmendisProgressCircleModule,
    EmendisPrimeProgressBarModule,
    HorizontalNavbarModule,
    EmendisPrimeMobileTabBarModule,
    ReactiveFormsModule,
    EmendisPrimeInputSwitchModule,
    EmendisPrimeFileUploadModule,
    EmendisPrimeDropdownModule
  ]
})
export class ProjectModule {
}
