import { Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgxPermissionsModule, NgxPermissionsService, NgxPermissionsStore } from 'ngx-permissions';
import { HttpClient } from '@angular/common/http';
import { MissingTranslationHandler, TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';

import { ApiModule } from '@emendis/api';
import { AuthGuard, AuthModule, UnauthenticatedGuard } from '@emendis/auth';
import { CoreModule } from '@emendis/complete';
import { LoginModule } from '@emendis/login';
import { CustomMissingTranslationHandler } from '@core/translate/custom-missing-translation-handler';
import { TranslationLoader } from '@core/translate/translation-loader';
import { CoreModule as MYEMCoreModule } from '@core/core.module';
import { environment } from '@environments/environment';
import { ProjectService } from '@shared/services/project.service';
import { BasicLayoutComponent } from '@features/layouts/basic-layout/basic-layout.component';
import { AppInjector } from './app-injector.service';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HorizontalNavbarModule } from '@emendis/ui';
import { SharedModule } from '@shared/shared.module';
import { ProjectModule } from '@features/project/project.module';
import { SelectManagerProjectModule } from '@features/project-manager/pages/select-manager-project/select-manager-project.module';

/** Error handler method for ApiConfig, function for when user is unauthenticated, route back to login etc. */
export function onAuthError(): void {
  window.location.href = '/login';
  localStorage.clear();
}

@NgModule({
  declarations: [
    AppComponent,
    BasicLayoutComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    TranslateModule.forRoot({
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: CustomMissingTranslationHandler
      },
      loader: {
        provide: TranslateLoader,
        deps: [HttpClient],
        useClass: TranslationLoader
      }
    }),
    ApiModule.forRoot({
      baseUrl: environment.baseUrl,
      production: environment.production,
      onAuthError: onAuthError
    }),
    AuthModule.forRoot({
      baseUrl: environment.baseUrl,
      production: environment.production,
      authRejectionRoute: '/auth/login',
      unauthenticatedRejectionRoute: '/'
    }),
    LoginModule.forRoot({
      environment: environment,
      productName: 'My Emendis',
      redirectAfterLogin: '/admin/projects'
    }),
    CoreModule.forRoot(
      {
        databaseName: environment.databaseName,
        version: 1,
        indexedDbModels: [],
        syncConfig: null
      },
      NgxPermissionsService,
      NgxPermissionsModule
    ),
    CoreModule,
    HorizontalNavbarModule,
    MYEMCoreModule,
    SharedModule,
    ProjectModule,
    SelectManagerProjectModule
  ],
  providers: [
    ProjectService,
    AuthGuard,
    UnauthenticatedGuard,
    TranslateService,
    NgxPermissionsStore
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(injector: Injector) {
    AppInjector.setInjector(injector);
  }
}
