import { Injectable } from '@angular/core';
import { ApiService } from '@emendis/api';
import { Notification } from '@core/models/notification-model';
import { Endpoints } from '@core/enums/generic/endpoints.enum';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService extends ApiService<Notification> {
  protected endpoint = Endpoints.notifications;
}
