import { EventEmitter, Injectable } from '@angular/core';
import { ApiService } from '@emendis/api';
import { Manual } from '@core/models/manual.model';
import { Observable } from '@node_modules/rxjs';
import { Endpoints } from '@core/enums/generic/endpoints.enum';
import * as faker from 'faker';

@Injectable({
  providedIn: 'root'
})
export class ManualService extends ApiService<Manual> {
  public onFormSubmitted = new EventEmitter<any>();
  public manuals: Manual[] = [];
  // TODO delete fake manuals data.
  public manualInfo: Manual[] = [
    { title: 'Emendis', id: faker.random.uuid(), files: [] },
    { title: 'Jira', id: faker.random.uuid(), files: [], },
    { title: 'myEmendis', id: faker.random.uuid(), files: [] },
  ];
  protected endpoint = Endpoints.manual;

  public fetchManualDocuments(): Observable<any> {
    return this.apiHttp.get(`/${this.endpoint}`);
  }

  // TODO send manuals to BE
  public receiveManualDocuments(manuals: any): void {
    // TODO Delete this code when BE is ready.
    for (let i = 0; i < this.manualInfo.length; i++) {
      this.manualInfo[i].files.push(manuals[i][0]);
    }
  }
}
