import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-form-input',
  templateUrl: './form-input.component.html',
})
export class FormInputComponent {
  @Input()
  public inputTitle: string;
  @Input()
  public control: any;
  @Input()
  public filterType?: string = 'email';
}
