export enum Flags {
  denmark = '../assets/flags/nde.svg',
  english = '../assets/flags/en.svg',
  spain = '../assets/flags/nes.svg',
  france = '../assets/flags/nfr.svg',
  greece = '../assets/flags/ngr.svg',
  italy = '../assets/flags/nit.svg',
  netherlands = '../assets/flags/nl.svg',
  portugal = '../assets/flags/npt.svg',
  united_states = '../assets/flags/nus.svg'
}
